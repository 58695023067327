import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { useTheme } from 'styled-components';
import Image from "gatsby-image";
import S from "./styles";
import ArrowIcon from "../../common/ArrowIcon";
import AniLink from "gatsby-plugin-transition-link/AniLink";

export interface ClientExperienceListProps {
  [prop: string]: any;
}

const ClientExperienceList = ({ ...rest }: ClientExperienceListProps) => {
  const { clientExperiences } = useStaticQuery(graphql`
    query ClientExperiencesList {
      clientExperiences: allContentfulClientExperience(
        filter: { slug: { ne: "dummy-client-experience" } }
        sort: { fields: number, order: ASC }
      ) {
        edges {
          node {
            engagement
            sector
            number
            slug
            excerpt {
              excerpt
            }
            image {
              fluid(quality: 80, maxWidth: 750) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);

  if (!clientExperiences || !clientExperiences.edges.length) {
    return null;
  }

  const theme = useTheme();

  return (
    <S.ClientExperienceList {...rest}>
      {clientExperiences.edges.map(({ node: experience }) => (
        <AniLink cover direction="up" bg={theme.colors.darkGrey1} to={`/client-experiences/${experience.slug}`}>
          <div className="client-experience" key={experience.number}>
            <Image fluid={experience.image.fluid} />
            <div className="content">
              <h4>
                {experience.number < 10
                  ? `0${experience.number}`
                  : experience.number}{" "}
                | {experience.sector}
              </h4>
              <h3>{experience.engagement}</h3>
              <p>{experience.excerpt.excerpt}</p>
              <p className="read-more">READ MORE <ArrowIcon colourScheme="light" /></p>
            </div>
            <ArrowIcon colourScheme="light" />
          </div>
        </AniLink>
      ))}
    </S.ClientExperienceList>
  );
};

export default ClientExperienceList;
