import styled from "styled-components";
import { Theme } from "../../../styles/theme";

const Text = styled.div`
  position: relative;
  flex: 1;
  ${({
    theme,
    duration,
    delay,
  }: {
    duration: number;
    delay?: number;
    theme: Theme;
  }) => theme.mixins.textAnimation(duration, delay)};
`;

export default {
  Text,
};
