import React from "react";
import ArrowIcon from "../ArrowIcon";
import S from "./styles";
import { ColourScheme } from "../../../types";

interface ReadMoreLinkProps {
  text?: string;
  colourScheme?: ColourScheme;
  link: string;
  [prop: string]: any;
}

const ReadMoreLink = ({
  text = "Read More",
  colourScheme,
  link = "",
  ...rest
}: ReadMoreLinkProps) => {
  return (
    <S.ReadMoreLink to={link} colourScheme={colourScheme} {...rest}>
      {text}
      <ArrowIcon colourScheme={colourScheme} />
    </S.ReadMoreLink>
  );
};

export default ReadMoreLink;
