import React from "react";
import S from "./styles";
import { ColourScheme } from "../../../types";
import AnimatedHeading from "../AnimatedHeading";

export interface UnderlineHeadingProps {
  children: any;
  colourScheme?: ColourScheme;
  [prop: string]: any;
}

const UnderlineHeading = ({
  children,
  colourScheme,
  ...rest
}: UnderlineHeadingProps) => {
  return (
      <S.UnderlineHeading colourScheme={colourScheme} {...rest}>
        <AnimatedHeading colourScheme={colourScheme} {...rest}>{children}</AnimatedHeading>
      </S.UnderlineHeading>
  );
};

export default UnderlineHeading;
