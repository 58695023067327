import React from "react";
import { graphql } from "gatsby";
import GatsbyImage from "gatsby-image";
import Container from "../../layout/Container";
import UnderlineHeading from "../../common/UnderlineHeading";
import ReadMoreLink from "../../common/ReadMoreLink";
import S from "./styles";
import { ContentfulFeaturedClientExperience } from "../../../types";

export const fragment = graphql`
  fragment FeaturedClientExperience on ContentfulFeaturedClientExperience {
    id
    internal {
      type
    }
    colourScheme
    clientExperience {
      number
      image {
        title
        fluid(quality: 80, maxWidth: 1200) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      engagement
      client
      sector
      slug
      excerpt {
        excerpt
      }
    }
  }
`;

export interface FeaturedClientExperienceProps
  extends ContentfulFeaturedClientExperience {
  pathContext: any;
  [prop: string]: any;
}

const FeaturedClientExperience = ({
  colourScheme,
  clientExperience: { number, slug, image, engagement, client, sector, excerpt },
  pathContext,
  ...rest
}: FeaturedClientExperienceProps) => {
  return (
    <S.FeaturedClientExperience colourScheme={colourScheme} {...rest}>
      <Container>
        <div className="inner">
          <UnderlineHeading className="mobile" colourScheme={colourScheme}>
              Client
              <br />
              Experience
          </UnderlineHeading>
          <GatsbyImage fluid={image.fluid} alt={image.title} />
          <div className="content">
            <UnderlineHeading colourScheme={colourScheme}>{"Client \nExperience"}</UnderlineHeading>
            <h5>
              Client Experience _{number < 10 ? `0${number}` : number} / <br />
              {sector}
            </h5>
            <h4>{engagement}</h4>
            {excerpt.excerpt}
            <ReadMoreLink
              colourScheme={colourScheme}
              link={`/client-experiences/${slug}`}
            />
          </div>
        </div>
      </Container>
    </S.FeaturedClientExperience>
  );
};

export default FeaturedClientExperience;
