import styled from 'styled-components';

const ImageTitleTiles = styled.section`
    position: relative;
    padding: 150px 0;
    background-color: ${({ colourScheme, theme }) => colourScheme === "dark" ? theme.colors.darkGrey1 : theme.colors.white1};

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
        padding: 50px 0 100px 0;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        padding: 85px 0 45px 0;
    }

    h3 {
        margin-bottom: 100px;

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
            margin-bottom: 50px;
        }
    }

    .tiles {
        position: relative;
        display: flex;
        margin: 0 -15px;

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
            overflow-x: scroll;
        }
    }
`

const Tile = styled.div`
    position: relative;
    width: 25%;
    padding: 0 15px;
    flex: 1;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
        min-width: 40%;
        padding-bottom: 40px;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        min-width: 75%;
        padding-bottom: 40px;
    }
    

    .gatsby-image-wrapper {
        height: 200px;
        width: 100%;
        margin-bottom: 20px;

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
            height: 170px;
        }
    }

    a {
        display: block;
        text-decoration: none;
        color: ${({ colourScheme, theme }) => colourScheme === "dark" ? theme.colors.white1 : theme.colors.darkGrey1};

        &:hover {
            svg {
                transform: translateX(0px);
            }
        }
    }

    .title {
        display: flex;
        justify-content: space-between;
        font-family: ${({ theme }) => theme.fonts.helveticaRegular};
        font-size: 24px;
        line-height: 36px;

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
            font-size: 20px;
            line-height: 30px;
        }

        h4 {
            padding-right: 20px;
            margin: 0;
        }

        svg {
            flex-shrink: 0;
            transition: transform 0.2s ease;
            transform: translateX(-10px);
        }
    }
`

export default {
    ImageTitleTiles,
    Tile
}