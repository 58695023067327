import styled from "styled-components";
import { ExcerptColumnsProps } from "./";

const ExcerptColumns = styled.section<ExcerptColumnsProps>`
  position: relative;
  padding: 120px 0;
  background-color: ${({ theme, colourScheme }) =>
    colourScheme === "dark" ? theme.colors.darkGrey1 : theme.colors.white1};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding: 60px 0;
  }

  .columns {
    display: flex;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
      flex-direction: column;
    }
  }
`;

const Column = styled.div<ExcerptColumnsProps>`
  position: relative;
  max-width: 465px;
  margin-right: auto;
  margin-left: 0;
  font-size: 20px;
  line-height: 33px;
  letter-spacing: -0.2px;
  color: ${({ theme, colourScheme }) =>
    colourScheme === "dark" ? theme.colors.white1 : theme.colors.darkGrey1};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: 100%;
    max-width: 100%;
    margin-bottom: 60px;
    font-size: 18px;
    line-height: 29px;

    &:nth-child(1) {
      padding-right: 20px;
    }

    &:nth-child(2) {
      padding-left: 20px;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    font-size: 16px;
    line-height: 26px;

    &:nth-child(1) {
      padding-right: 0px;
    }

    &:nth-child(2) {
      padding-left: 0px;
    }
  }

  &:nth-of-type(2) {
    margin-left: auto;
    margin-right: 0;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
      margin-bottom: 0px;
    }
  }

  h3 {
    margin-bottom: 70px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
      margin-bottom: 35px;
    }
  }

  .excerpt {
    margin-bottom: 40px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
      margin-bottom: 20px;
    }
  }
`;

export default {
  ExcerptColumns,
  Column,
};
