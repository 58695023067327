import styled from 'styled-components';

const FeaturedContent = styled.section`
    position: relative;
    padding: 120px 0;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
        padding: 60px 0 90px 0;
    }

    .gatsby-image-wrapper {
        z-index: 1;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        &:after {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0.5;
            background-color: ${({ theme}) => theme.colors.darkGrey1};
        }

        img {
            object-fit: cover;
            object-position: center;
            pointer-events: none;
            position: fixed;
        }
    }

    .inner {
        position: relative;
        z-index: 2;
        max-width: 700px;
        margin-right: auto;
    }

    h2 {
        font-size: 100px;
        line-height: 100px;
        margin: 45px 0 30px 0;
        color: ${({ theme}) => theme.colors.white1};
        font-family: ${({ theme}) => theme.fonts.helveticaBold};
        text-transform: uppercase;
        white-space: pre-wrap;

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
            font-size: 50px;
            line-height: 50px;
            margin-top: 65px;
        }

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
            font-size: 38px;
            line-height: 38px;
        }
    }

    .content {
        font-size: 20px;
        line-height: 33px;
        color: ${({ theme}) => theme.colors.white2};
        margin-bottom: 30px;

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
            font-size: 18px;
            line-height: 28px;
        }

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
            font-size: 17px;
            line-height: 26px;
        }
    }
`

export default {
    FeaturedContent
}