import styled from 'styled-components';
import { UnderlineHeadingProps } from './';

const UnderlineHeading = styled.h3<UnderlineHeadingProps>`
    position: relative;
    font-size: 44px;
    line-height: 50px;
    color: ${({ colourScheme, theme }) => colourScheme === "dark" ? theme.colors.white2 : theme.colors.darkGrey1};
    letter-spacing: -0.9px;
    text-transform: uppercase;
    margin: 0;
    z-index: 2;
    white-space: pre-wrap;

    > div {
        ${({theme}) => theme.mixins.headingGreenUnderline};
    }
    
    ${({ theme, colourScheme }) => theme.mixins.headingAnimation(colourScheme)};

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
        font-size: 30px;
        line-height: 32px;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        font-size: 22px;
        line-height: 24px;
    }
`

export default {
    UnderlineHeading
}