import React, { useEffect } from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import Container from "../../layout/Container";
import S from "./styles";
import "./style.css";
import { ContentfulJobAdderWidget } from "../../../types";

export const fragment = graphql`
  fragment JobAdderWidget on ContentfulJobAdderWidget {
    id
    internal {
      type
    }
    jobsPerPage
    showSearchForm
    useExternalApplicationForm
  }
`;

export interface JobAdderWidgetProps extends ContentfulJobAdderWidget {
  [prop: string]: any;
}

const JobAdderWidget = ({
  jobsPerPage,
  showSearchForm,
  useExternalApplicationForm,
  ...rest
}: JobAdderWidgetProps) => {
  return (
    <S.JobAdderWidget {...rest}>
      <Helmet>
        <script>
          {`
            
            window._jaJobsSettings = {
              key: "AU3_tjs5bikoufzulkqsuw2x6vq2gu",
              showLoadingImage: false,
              loadingImageUrl: "",
              jobSearchSettings: {
                showSearchForm: ${showSearchForm},
                optionText: "Please select...",
                autoSearch: true,
                showSearchButton: false,
                showKeywordSearch: false,
                searchButtonText: "Search",
              },
              jobListSettings: {
                jobsPerPage: ${jobsPerPage},
                showHotJobsOnly: false,
                alwaysShowPager: true,
                animateScrollOnPageChange: true,
                noJobsContent: "There are no jobs matching your criteria.",
              },
              applicationFormSettings: {
                useExternalApplicationForm: ${useExternalApplicationForm},
              },
            };
            `}
        </script>
        <script src="//apps.jobadder.com/widgets/v1/jobs.min.js"></script>
      </Helmet>
      <Container>
        <h2 className="section-title">CURRENT OPPORTUNITIES</h2>
        <div id="ja-jobs-widget"></div>
      </Container>
    </S.JobAdderWidget>
  );
};

export default JobAdderWidget;
