import React from "react";
import { graphql } from "gatsby";
import GatsbyImage from "gatsby-image";
import Container from "../../layout/Container";
import UnderlineHeading from "../../common/UnderlineHeading";
import ReadMoreLink from "../../common/ReadMoreLink";
import { getSlugByID } from "../../../utils/helpers";
import S from "./styles";
import { ContentfulFeaturedContent, ContentfulLongText } from "../../../types";

export const fragment = graphql`
  fragment FeaturedContent on ContentfulFeaturedContent {
    id
    internal {
      type
    }
    backgroundImage {
      fluid(quality: 80, maxWidth: 1600) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    fcHeading: heading {
      heading
    }
    subheading {
      subheading
    }
    excerpt {
      excerpt
    }
    readMoreLink {
      contentful_id
    }
  }
`;

export interface FeaturedContentProps extends ContentfulFeaturedContent {
  fcHeading: ContentfulLongText;
  pathContext: any;
}

const FeaturedContent = ({
  fcHeading,
  subheading,
  excerpt,
  readMoreLink,
  backgroundImage,
  pathContext,
  ...rest
}: FeaturedContentProps) => {
  const { pageMap } = pathContext;

  return (
    <S.FeaturedContent {...rest}>
      <Container>
        <div className="inner">
          {subheading && (
            <UnderlineHeading colourScheme="dark">
              {subheading.subheading}
            </UnderlineHeading>
          )}
          {fcHeading && <h2>{fcHeading.heading}</h2>}
          <div className="content">
            {excerpt.excerpt}
          </div>
          {readMoreLink && (
            <ReadMoreLink
              colourScheme="dark"
              link={getSlugByID({
                pageMap,
                pageID: readMoreLink.contentful_id,
              })}
            />
          )}
        </div>
      </Container>
      <GatsbyImage
        fluid={backgroundImage.fluid}
        alt={backgroundImage.title}
        style={{ position: "absolute" }}
      />
    </S.FeaturedContent>
  );
};

export default FeaturedContent;
