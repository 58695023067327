import styled from 'styled-components';
import TransitionLink from 'gatsby-plugin-transition-link';

const ReadMoreLink = styled(TransitionLink)`
    position: relative;
    font-family: ${({ theme }) => theme.fonts.rajdhaniSemiBold};
    text-decoration: none;
    font-size: 19px;
    line-height: 30px;
    letter-spacing: 1.3px;
    text-transform: uppercase;
    color: ${({ theme, colourScheme }) => colourScheme === "dark" ? theme.colors.white1 : theme.colors.darkGrey1};
    display: flex;
    align-items: center;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
        font-size: 17px;
        line-height: 27px;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        font-size: 15px;
        line-height: 24px;
    }

    &:hover {
        svg {
            transform: translateX(20px);
        }
    }

    svg {
        transition: transform 0.2s ease;
        margin-left: 60px;
        transform: translateX(0);

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
            width: 24px;
            height: 24px;
        }
    }
`

export default {
    ReadMoreLink
}