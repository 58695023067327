import React, { useEffect, useContext } from "react";
import { graphql } from "gatsby";
import Container from "../../layout/Container";
import ClientExperienceList from '../ClientExperienceList';
import S from "./styles";
import { HeaderContext } from "../../../context/HeaderContext";
import { ContentfulClientExperiencesSettings } from "../../../types";
import AnimatedHeading from '../../common/AnimatedHeading';

export interface ClientExperiencesHomeProps
  extends ContentfulClientExperiencesSettings {
  [prop: string]: any;
}

export const fragment = graphql`
  fragment ClientExperiencesSettings on ContentfulClientExperiencesSettings {
    id
    internal {
      type
    }
    ceHeading: heading {
      heading
    }
    introContent {
      introContent
    }
  }
`;

const ClientExperiencesHome = ({
  ceHeading,
  introContent,
  ...rest
}: ClientExperiencesHomeProps) => {
  const { setHeaderColor, setInitialHeaderColor } = useContext(HeaderContext);

  useEffect(() => {
    setHeaderColor("light");
    setInitialHeaderColor("light");
  }, []);

  return (
    <S.ClientExperiencesHome {...rest}>
      <Container>
        {ceHeading && (
          <div className="heading">
              <h1><AnimatedHeading colourScheme="light">{ceHeading.heading}</AnimatedHeading></h1>
          </div>
        )}
        {introContent && (
          <div className="intro">
            <div className="inner">{introContent.introContent}</div>
          </div>
        )}
        <ClientExperienceList />
      </Container>
    </S.ClientExperiencesHome>
  );
};

export default ClientExperiencesHome;
