import React, { useEffect, useContext } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Container from "../../layout/Container";
import ArticleList from "../ArticleList";
import ArticleCategories from '../ArticleCategories';
import S from "./styles";
import { HeaderContext } from "../../../context/HeaderContext";
import { ContentfulArticlesSettings } from "../../../types";
import AnimatedHeading from "../../common/AnimatedHeading";

export interface ArticlesHomeProps extends ContentfulArticlesSettings {
  [prop: string]: any;
}

export const fragment = graphql`
  fragment ArticlesSettings on ContentfulArticlesSettings {
    id
    internal {
      type
    }
    articleHeading: heading {
      heading
    }
  }
`;

const ArticlesHome = ({ articleHeading, ...rest }: ArticlesHomeProps) => {
  let { articles } = useStaticQuery(graphql`
  query ArticleList {
    articles: allContentfulArticle(
      filter: { slug: { ne: "dummy-article" } }
    ) {
      edges {
        node {
          title
          slug
          category {
            title
            slug
          }
          image {
            fluid(quality: 80, maxWidth: 1000) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          date(formatString: "D MMMM YYYY")
          excerpt {
            excerpt
          }
        }
      }
    }
  }
`);

  articles = articles.edges.map(article => ({ ...article.node }));

  const { setHeaderColor, setInitialHeaderColor } = useContext(HeaderContext);

  useEffect(() => {
    setHeaderColor("light");
    setInitialHeaderColor("light");
  }, []);

  return (
    <S.ArticlesHome {...rest}>
      <Container>
        {articleHeading && (
          <div className="heading">
            <h1><AnimatedHeading colourScheme="light">{articleHeading.heading}</AnimatedHeading></h1>
          </div>
        )}
        <div className="inner">
          <ArticleCategories className="category-list" />
          <ArticleList className="article-list" articles={articles} />
        </div>
      </Container>
    </S.ArticlesHome>
  );
};

export default ArticlesHome;
