import React from "react";
import { graphql } from "gatsby";
import Image from 'gatsby-image';
import Container from "../../layout/Container";
import UnderlineHeading from "../../common/UnderlineHeading";
import S from "./styles";
import { ContentfulAwards } from '../../../types';

export const fragment = graphql`
  fragment Awards on ContentfulAwards {
    id
    internal {
      type
    }
    colourScheme
    awardsHeading: heading {
      heading
    }
    images {
      fluid(quality: 80, maxWidth: 500) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
  }
`;

export interface AwardsProps extends ContentfulAwards {
  [prop: string]: any;
}

const Awards = ({
  colourScheme,
  awardsHeading,
  images,
  ...rest
}: ContentfulAwards) => {
  return (
    <S.Awards colourScheme={colourScheme} {...rest}>
      <Container>
        <div className="inner">
          {awardsHeading && (
            <UnderlineHeading colourScheme={colourScheme}>
              {awardsHeading.heading}
            </UnderlineHeading>
          )}
          <div className="awards">
            {images.map(image => <S.Award><Image fluid={image.fluid} /></S.Award>)}
          </div>
        </div>
      </Container>
    </S.Awards>
  );
};

export default Awards;
