import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import S from "./styles";

interface AnimatedTextProps {
  children: any;
  duration?: number;
  delay: number;
  className?: string;
}

const AnimatedText = ({
  duration,
  delay,
  children,
  className,
}: AnimatedTextProps) => {
  const [animate, setAnimate] = useState(false);

  const onChange = isVisible => {
    if (isVisible) {
      setAnimate(true);
    }
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility={true}>
      <S.Text
        duration={duration}
        delay={delay}
        className={animate ? `${className} animate` : `${className}`}
      >
        {children}
      </S.Text>
    </VisibilitySensor>
  );
};

export default AnimatedText;
