import styled from 'styled-components';

const ArticlesHome = styled.section`
    position: relative;
    padding: 300px 0;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
        padding: 240px 0;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        padding: 180px 0;
    }

    .heading {
        position: relative;
        border-bottom: 1px solid ${({ theme }) => theme.colors.darkGrey1};
        padding-bottom: 60px;
        margin-bottom: 40px;

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
            margin-bottom: 20px;
        }
        
        h1 {
            font-family: ${({ theme }) => theme.fonts.helveticaBold};
            text-transform: uppercase;
            font-size: 90px;
            line-height: 88px;
            letter-spacing: -1.7px;
            white-space: pre-wrap;

            @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
                font-size: 50px;
                line-height: 50px;
            }

            @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
                font-size: 40px;
                line-height: 40px;
            }
        }
    }

    .inner {
        display: flex;

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
            flex-direction: column;
        }

        .category-list {
            flex: 1;
        }

        .article-list {
            flex: 3;
            padding-left: 120px;

            @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
                padding-left: 0;
            }
        }
    }
`

export default {
    ArticlesHome
}