import React from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import {
  contentfulRichTextLinks,
  contentfulRichTextInlineImage,
  contentfulRichTextBlockquoteWithQuoteSVG,
} from "../../../../utils/helpers";
import S from "./styles";
import { ContentfulHalfContentContentColumnSection } from '../../../../types';

export interface HalfContentProps extends ContentfulHalfContentContentColumnSection {
  [prop: string]: any;
}

const HalfContent = ({ halfContent, colourScheme, pathContext }: HalfContentProps) => {
  const { pageMap } = pathContext;

  return (
    <S.HalfContent>
      {halfContent &&
        documentToReactComponents(halfContent.json, {
          renderNode: {
            ...contentfulRichTextLinks({ pageMap }),
            ...contentfulRichTextInlineImage,
            ...contentfulRichTextBlockquoteWithQuoteSVG({ colourScheme }),
          },
        })}
    </S.HalfContent>
  );
};

export default HalfContent;
