import styled from 'styled-components';

const ContentSpacer = styled.div`
    position: relative;
    padding-top: ${({ desktopSpace}) => desktopSpace + "px"};

    // TODO - add in dynamic mobile size
    @media screen and (max-width: 750px) {
        padding-top: ${({ mobileSpace}) => mobileSpace + "px"};
    }
`

export default {
    ContentSpacer
}