import React from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import {
  contentfulRichTextLinks,
  contentfulRichTextInlineImage,
  contentfulRichTextBlockquoteWithQuoteSVG,
} from "../../../../utils/helpers";
import S from "./styles";
import { ContentfulBasicContentContentColumnSection } from '../../../../types';

export interface BasicContentProps extends ContentfulBasicContentContentColumnSection {
  [prop: string]: any;
}

const BasicContent = ({ basicContent, colourScheme, pathContext }: BasicContentProps) => {
  const { pageMap } = pathContext;

  return (
    <S.BasicContent>
      {documentToReactComponents(basicContent.json, {
        renderNode: {
          ...contentfulRichTextLinks({ pageMap }),
          ...contentfulRichTextInlineImage,
          ...contentfulRichTextBlockquoteWithQuoteSVG({ colourScheme }),
        },
      })}
    </S.BasicContent>
  );
};

export default BasicContent;
