import React from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import {
  contentfulRichTextLinks,
  contentfulRichTextInlineImage,
  contentfulRichTextBlockquoteWithQuoteSVG,
} from "../../../../utils/helpers";
import S from "./styles";
import {
  ColourScheme,
  ContentfulTwoColumnContentContentColumnSection,
} from "../../../../types";

interface TwoColumnContentProps
  extends ContentfulTwoColumnContentContentColumnSection {
  colourScheme: ColourScheme;
  pathContext: any;
}

const TwoColumnContent = ({
  twoColumnContent1,
  twoColumnContent2,
  colourScheme,
  pathContext,
}: TwoColumnContentProps) => {
  const { pageMap } = pathContext;

  return (
    <S.TwoColumnContent>
      <S.Column>
        {twoColumnContent1 &&
          documentToReactComponents(twoColumnContent1.json, {
            renderNode: {
              ...contentfulRichTextLinks({ pageMap }),
              ...contentfulRichTextInlineImage,
              ...contentfulRichTextBlockquoteWithQuoteSVG({ colourScheme }),
            },
          })}
      </S.Column>
      <S.Column>
        {twoColumnContent2 &&
          documentToReactComponents(twoColumnContent2.json, {
            renderNode: {
              ...contentfulRichTextLinks({ pageMap }),
              ...contentfulRichTextInlineImage,
              ...contentfulRichTextBlockquoteWithQuoteSVG({ colourScheme }),
            },
          })}
      </S.Column>
    </S.TwoColumnContent>
  );
};

export default TwoColumnContent;
