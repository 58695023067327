import React from "react";
import { graphql } from "gatsby";
import Container from "../../layout/Container";
import UnderlineHeading from "../../common/UnderlineHeading";
import S from "./styles";
import { ContentfulIconFeatures } from '../../../types';

export const fragment = graphql`
  fragment IconFeatures on ContentfulIconFeatures {
    id
    internal {
      type
    }
    colourScheme
    ifHeading: heading {
      heading
    }
    iconImage1 {
      file {
        url
      }
    }
    iconTitle1 {
      iconTitle1
    }
    iconImage2 {
      file {
        url
      }
    }
    iconTitle2 {
      iconTitle2
    }
    iconImage3 {
      file {
        url
      }
    }
    iconTitle3 {
      iconTitle3
    }
  }
`;

export interface IconFeaturesProps extends ContentfulIconFeatures {
  [prop: string]: any;
}

const IconFeatures = ({
  colourScheme,
  ifHeading,
  iconImage1,
  iconTitle1,
  iconImage2,
  iconTitle2,
  iconImage3,
  iconTitle3,
  pathContext,
  ...rest
}: ContentfulIconFeatures) => {
  return (
    <S.IconFeatures colourScheme={colourScheme} {...rest}>
      <Container>
        <div className="inner">
          {ifHeading && (
            <UnderlineHeading colourScheme={colourScheme}>
              {ifHeading.heading}
            </UnderlineHeading>
          )}
          <div className="features">
            {iconImage1 && (
              <S.Feature colourScheme={colourScheme}>
                <img src={iconImage1.file.url} />
                {iconTitle1 && <h4>{iconTitle1.iconTitle1}</h4>}
              </S.Feature>
            )}
            {iconImage2 && (
              <S.Feature colourScheme={colourScheme}>
                <img src={iconImage2.file.url} />
                {iconTitle2 && <h4>{iconTitle2.iconTitle2}</h4>}
              </S.Feature>
            )}
            {iconImage3 && (
              <S.Feature colourScheme={colourScheme}>
                <img src={iconImage3.file.url} />
                {iconTitle3 && <h4>{iconTitle3.iconTitle3}</h4>}
              </S.Feature>
            )}
          </div>
        </div>
      </Container>
    </S.IconFeatures>
  );
};

export default IconFeatures;
