import React, { useEffect, useContext } from "react";
import Image from "gatsby-image";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { graphql } from "gatsby";
import Container from "../../layout/Container";
import UnderlineHeading from "../../common/UnderlineHeading";
import LinkedinIcon from "../../common/LinkedinIcon";
import YouTubeIcon from "../../common/YouTubeIcon";
import ContactForm from "../ContactForm";
import S from "./styles";
import { HeaderContext } from "../../../context/HeaderContext";
import { SettingsContext } from "../../../context/SettingsContext";
import { ContentfulContactSettings } from "../../../types";
import AnimatedHeading from "../../common/AnimatedHeading";

export const fragment = graphql`
  fragment ContactContent on ContentfulContactSettings {
    id
    internal {
      type
    }
    ccHeading: heading {
      heading
    }
    introContent {
      json
    }
    locationsHeading {
      locationsHeading
    }
    locations {
      heading
      city
      address {
        address
      }
      mapLatitude
      mapLongitude
    }
    contactFormHeading {
      contactFormHeading
    }
  }
`;

export interface ContactContentProps extends ContentfulContactSettings {
  [prop: string]: any;
}

const mapStyles = [
  {
    featureType: "all",
    elementType: "labels.text.fill",
    stylers: [
      {
        saturation: 36,
      },
      {
        color: "#000000",
      },
      {
        lightness: 40,
      },
    ],
  },
  {
    featureType: "all",
    elementType: "labels.text.stroke",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#000000",
      },
      {
        lightness: 16,
      },
    ],
  },
  {
    featureType: "all",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 17,
      },
      {
        weight: 1.2,
      },
    ],
  },
  {
    featureType: "administrative.locality",
    elementType: "labels",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "administrative.neighborhood",
    elementType: "labels",
    stylers: [
      {
        visibility: "simplified",
      },
    ],
  },
  {
    featureType: "administrative.neighborhood",
    elementType: "labels.text.fill",
    stylers: [
      {
        lightness: "17",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "labels",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "landscape.man_made",
    elementType: "labels",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "landscape.man_made",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "landscape.natural",
    elementType: "labels",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 21,
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#00BF8F",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.fill",
    stylers: [
      {
        lightness: 17,
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 29,
      },
      {
        weight: 0.2,
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels",
    stylers: [
      {
        invert_lightness: true,
      },
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "road.highway.controlled_access",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#00BF6F",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 18,
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#00BF6F",
      },
      {
        lightness: "39",
      },
      {
        gamma: "0.43",
      },
      {
        saturation: "-47",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 16,
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#555555",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 19,
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 17,
      },
    ],
  },
];

const ContactContent = ({
  ccHeading,
  introContent,
  locationsHeading,
  locations,
  contactFormHeading,
  ...rest
}: ContactContentProps) => {
  const { setHeaderColor, setInitialHeaderColor } = useContext(HeaderContext);
  const {
    settings: { linkedinUrl, youTubeUrl, googleMapsApiKey, getformEndpoint },
  } = useContext(SettingsContext);

  useEffect(() => {
    setHeaderColor("dark");
    setInitialHeaderColor("dark");
  }, []);

  return (
    <S.ContactContent {...rest}>
      <Container>
        <>
          {ccHeading && (
            <div className="heading">
              <h1>
                <AnimatedHeading colourScheme="dark">
                  {ccHeading.heading}
                </AnimatedHeading>
              </h1>
            </div>
          )}
          {introContent && (
            <div className="intro">
              {(linkedinUrl || youTubeUrl) && (
                <div className="social">
                  {linkedinUrl && (
                    <a className="linkedin" href={linkedinUrl}>
                      <LinkedinIcon colourScheme="dark" />
                    </a>
                  )}
                  {youTubeUrl && (
                    <a className="youtube" href={youTubeUrl}>
                      <YouTubeIcon colourScheme="dark" />
                    </a>
                  )}
                </div>
              )}
              <div className="inner">
                {documentToReactComponents(introContent.json)}
              </div>
            </div>
          )}
          <div className="contact-form">
            <div className="sub-heading">
              {contactFormHeading && (
                <UnderlineHeading colourScheme="dark">
                  {contactFormHeading.contactFormHeading}
                </UnderlineHeading>
              )}
            </div>
            <div className="form">
              <ContactForm endpoint={getformEndpoint} />
            </div>
          </div>
          {locations && (
            <div className="locations">
              <div className="sub-heading">
                {locationsHeading && (
                  <UnderlineHeading colourScheme="dark">
                    {locationsHeading.locationsHeading}
                  </UnderlineHeading>
                )}
              </div>
              <div className="list">
                {locations.map(location => (
                  <div className="location">
                    <div className="google-map">
                      <LoadScript googleMapsApiKey={googleMapsApiKey}>
                        <GoogleMap
                          center={{
                            lat: location.mapLatitude,
                            lng: location.mapLongitude,
                          }}
                          zoom={15}
                          options={{
                            styles: mapStyles,
                            streetViewControl: false,
                            mapTypeControl: false,
                            fullscreenControl: false,
                          }}
                          mapContainerStyle={{
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Marker
                            position={{
                              lat: location.mapLatitude,
                              lng: location.mapLongitude,
                            }}
                            icon={{ url: "/map-pin.png" }}
                          />
                        </GoogleMap>
                      </LoadScript>
                    </div>
                    <div className="details">
                      {location.heading && <h5>{location.heading}</h5>}
                      <h4>{location.city}</h4>
                      <p>{location.address.address}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      </Container>
    </S.ContactContent>
  );
};

export default ContactContent;
