import styled from 'styled-components';

const ContactContent = styled.section`
    position: relative;
    padding: 300px 0;
    background-color: ${({ theme }) => theme.colors.darkGrey1};

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
        padding: 240px 0;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        padding: 180px 0;
    }

    .heading {
        position: relative;
        border-bottom: 1px solid ${({ theme }) => theme.colors.white2};
        padding-bottom: 60px;
        
        h1 {
            font-family: ${({ theme }) => theme.fonts.helveticaBold};
            text-transform: uppercase;
            font-size: 90px;
            line-height: 88px;
            letter-spacing: -1.7px;
            white-space: pre-wrap;
            color: ${({ theme }) => theme.colors.white1};

            @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
                font-size: 50px;
                line-height: 50px;
            }

            @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
                font-size: 40px;
                line-height: 40px;
            }
        }
    }

    .intro {
        position: relative;
        padding: 100px 0;

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
            padding: 80px 0;
        }

        .social {
            position: absolute;
            top: 100px;
            left: 0;
            display: flex;
            align-items: center;

            .linkedin {
                margin-right: 20px;
            }

            .youtube {
                svg {
                    height: 35px;
                    width: 35px;
                }
                
            }

            @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
                position: relative;
                top: 0;
                margin-bottom: 20px;
                display: block;
            }
        }

        .inner {
            width: 66.6666%;
            margin-left: auto;
            color: ${({ theme }) => theme.colors.white2};
            font-family: ${({ theme }) => theme.fonts.poppinsRegular};
            font-size: 20px;
            line-height: 33px;

            @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
                font-size: 18px;
                line-height: 28px;
            }

            @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
                width: 100%;
                font-size: 16px;
                line-height: 26px;
            }

            h6 {
                font-size: 32px;
                line-height: 46px;
                letter-spacing: -0.6px;
                margin-top: 0;
                font-family: ${({ theme }) => theme.fonts.helveticaRegular};

                @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
                    font-size: 24px;
                    line-height: 36px;
                }

                @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
                    font-size: 20px;
                    line-height: 30px;
                }
            }
        }
    }

    .locations,
    .contact-form {
        display: flex;

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
            flex-direction: column;
        }

        h3 {
            margin-top: 0;

            @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
                margin-bottom: 75px;
            }
        }

        .list,
        .form {
            width: 66.6666%;
            margin-left: auto;

            @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
                flex-direction: column;
                width: 100%;
            }
        }

        .form {
            padding-bottom: 120px;
        }

        .location {
            display: flex;
            color: ${({ theme }) => theme.colors.white2};
            padding-bottom: 90px;

            @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
                flex-direction: column;
                padding-bottom: 50px;
            }

            .google-map {
                width: 400px;
                height: 300px;
                object-fit: cover;

                @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
                    width: 100%;
                    height: 300px;
                }
            }

            .details {
                padding-left: 40px;
                font-family: ${({ theme }) => theme.fonts.poppinsRegular};
                font-size: 20px;
                line-height: 33px;
                flex: 1;
                letter-spacing: 0.2px;

                @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
                    padding-left: 0;
                    padding-top: 40px;
                }

                @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
                    padding-left: 0;
                    padding-top: 30px;
                }

                h4 {
                    margin-top: 0;
                    font-family: ${({ theme }) => theme.fonts.helveticaRegular};
                    font-size: 32px;
                    line-height: 46px;  
                    margin-bottom: 10px;

                    @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
                        font-size: 26px;
                        line-height: 36px;
                    }

                    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
                        font-size: 22px;
                        line-height: 32px;
                    }
                }

                h5 {
                    margin-top: 0;
                    margin-bottom: 10px;
                    font-family: ${({ theme }) => theme.fonts.rajdhaniSemiBold};
                    text-transform: uppercase;
                    font-size: 19px;
                    line-height: 30px;
                    letter-spacing: 1.8px;

                    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
                        font-size: 18px;
                    }
                }

                p {
                    white-space: pre-wrap;
                    font-size: 16px;
                    line-height: 26px;
                }
            }
        }
    }
`

export default {
    ContactContent
}