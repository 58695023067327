import React, { useState } from "react";
import { graphql } from "gatsby";
import Container from "../../layout/Container";
import UnderlineHeading from "../../common/UnderlineHeading";
import ReadMoreLink from "../../common/ReadMoreLink";
import { getSlugByID } from "../../../utils/helpers";
import S from "./styles";
import { ContentfulExcerptColumns } from "../../../types";

export const fragment = graphql`
  fragment ExcerptColumns on ContentfulExcerptColumns {
    id
    internal {
      type
    }
    colourScheme
    leftColumnTitle {
      leftColumnTitle
    }
    leftColumnExcerpt {
      leftColumnExcerpt
    }
    leftColumnReadMoreLink {
      contentful_id
    }
    rightColumnTitle {
      rightColumnTitle
    }
    rightColumnExcerpt {
      rightColumnExcerpt
    }
    rightColumnReadMoreLink {
      contentful_id
    }
  }
`;

export interface ExcerptColumnsProps extends ContentfulExcerptColumns {
  pathContext?: any;
  [prop: string]: any;
}

const ExcerptColumns = ({
  colourScheme,
  leftColumnTitle,
  leftColumnExcerpt,
  leftColumnReadMoreLink,
  rightColumnTitle,
  rightColumnExcerpt,
  rightColumnReadMoreLink,
  pathContext,
  ...rest
}: ExcerptColumnsProps) => {
  const { pageMap } = pathContext;

  return (
    <S.ExcerptColumns colourScheme={colourScheme} {...rest}>
      <Container>
        <div className="columns">
          <S.Column colourScheme={colourScheme}>
            <UnderlineHeading colourScheme={colourScheme}>
              {leftColumnTitle.leftColumnTitle}
            </UnderlineHeading>
            <div className="excerpt">{leftColumnExcerpt.leftColumnExcerpt}</div>
            {leftColumnReadMoreLink && (
              <ReadMoreLink
                className="read-more"
                colourScheme={colourScheme}
                link={getSlugByID({
                  pageMap,
                  pageID: leftColumnReadMoreLink.contentful_id,
                })}
              />
            )}
          </S.Column>
          <S.Column colourScheme={colourScheme}>
            <UnderlineHeading colourScheme={colourScheme}>
              {rightColumnTitle.rightColumnTitle}
            </UnderlineHeading>
            <div className="excerpt">
              {rightColumnExcerpt.rightColumnExcerpt}
            </div>
            {rightColumnReadMoreLink && (
              <ReadMoreLink
                colourScheme={colourScheme}
                link={getSlugByID({
                  pageMap,
                  pageID: rightColumnReadMoreLink.contentful_id,
                })}
              />
            )}
          </S.Column>
        </div>
      </Container>
    </S.ExcerptColumns>
  );
};

export default ExcerptColumns;
