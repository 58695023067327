import React, { useContext } from "react";
import { useTheme } from 'styled-components';
import Image from "gatsby-image";
import S from "./styles";
import ArrowIcon from "../../common/ArrowIcon";
import { SettingsContext } from "../../../context/SettingsContext";
import { ContentfulArticle } from '../../../types';

export interface ArticleListProps {
  articles: ContentfulArticle[];
  [prop: string]: any;
}

const ArticleList = ({ articles, ...rest }: ArticleListProps) => {
  if (!articles || !articles.length) {
    return null;
  }

  const theme = useTheme();
  const { settings } = useContext(SettingsContext);

  return (
    <S.ArticleList {...rest}>
      {articles.map((article) => (
        <S.Article cover direction="up" bg={theme.colors.darkGrey1} to={`${settings.articlesPageSlug}/${article.category.slug}/${article.slug}`}>
          <div className="image">
            <Image fluid={article.image.fluid} />
          </div>
          <div className="details">
            <div className="meta">
              <div className="category">{article.category.title}</div>
              <div className="date">{article.date}</div>
            </div>
            <div className="content">
              <h3>{article.title}</h3>
              <div className="excerpt">
                {article.excerpt.excerpt}
              </div>
              <p className="read-more">READ MORE <ArrowIcon colourScheme="light" /></p>
            </div>
          </div>
        </S.Article>
      ))}
    </S.ArticleList>
  );
};

export default ArticleList;
