import AniLink from 'gatsby-plugin-transition-link/AniLink';
import styled from 'styled-components';

const ArticleList = styled.div`
    position: relative;
`

const Article = styled(AniLink)`
    position: relative;
    display: flex;
    text-decoration: none;
    padding-bottom: 35px;
    margin-bottom: 50px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.mediumGrey1};
    align-items: stretch;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        flex-direction: column;
    }

    .gatsby-image-wrapper {
        height: 100%;
        width: 400px;
        flex: 1;

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
            width: 100%;
            height: 300px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    .details {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
        padding-left: 40px;

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
            padding-left: 0;
            margin-top: 20px;
        }

        .meta {
            font-size: 18px;
            line-height: 29px;

            @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
                font-size: 16px;
                line-height: 26px;
            }

            .date {
                font-size: 14px;
                color: ${({ theme }) => theme.colors.mediumGrey2};
            }
        }

        .content {
            font-size: 18px;
            line-height: 26px;

            @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
                font-size: 16px;
                line-height: 26px;
            }

            h3 {
                font-size: 22px;
                font-family: ${({ theme }) => theme.fonts.poppinsSemiBold};
                margin-bottom: 10px;
            }

            .read-more {
                display: flex;
                justify-content: space-between;
                display: none;
                position: relative;
                font-size: 19px;
                line-height: 30px;
                font-family: ${({ theme }) => theme.fonts.rajdhaniSemiBold};
                margin-top: 30px;
                letter-spacing: 1.8px;

                @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
                    display: flex;
                    margin-bottom: 0;
                }

                svg {
                    position: relative;
                    display: block;
                    width: 30px;
                    height: 30px;
                }
            }
        }
    }
`

export default {
    ArticleList,
    Article
}