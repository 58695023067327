import styled from "styled-components";
import { AlternatingPanelsProps, PanelProps } from "./";
import { Theme } from "../../../styles/theme";

const AlternatingPanels = styled.section<AlternatingPanelsProps>`
  position: relative;
  padding: 150px 0;
  background-color: ${({ theme, colourScheme }) =>
    colourScheme === "dark" ? theme.colors.darkGrey1 : theme.colors.white1};
  color: ${({ theme, colourScheme }) =>
    colourScheme === "dark" ? theme.colors.lightGrey2 : theme.colors.darkGrey1};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding: 100px 0;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    padding: 60px 0;
  }

  .panels {
    position: relative;
  }
`;

const Panel = styled.div<PanelProps>`
  display: flex;
  padding-bottom: 100px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    flex-direction: column;
  }

  &:last-of-type {
    padding-bottom: 0;
  }

  &:nth-of-type(even) {
    .image {
      order: 2;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        order: 1;
      }
    }

    .content {
      padding-left: 0;
      padding-right: 60px;
      order: 1;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        padding-right: 0;
      }
    }
  }

  .image {
    flex: 2;
    max-width: 760px;
    order: 1;
  }

  .content {
    display: flex;
    flex: 1;
    padding-left: 60px;
    align-items: center;
    order: 2;
    font-size: 18px;
    line-height: 29px;
    letter-spacing: 0.3px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
      padding-left: 0;
      padding-top: 20px;
      font-size: 15px;
      line-height: 24px;
    }

    .inner {
      position: relative;

      &:before {
        position: absolute;
        visibility: ${({ colourScheme }) =>
          colourScheme === "dark" ? "visible" : "hidden"};
        content: "";
        background-color: ${({ theme }) => theme.colors.green1};
        width: 120px;
        height: 2px;
        top: -20px;

        @media screen and (max-width: ${({ theme }) =>
            theme.breakpoints.small}) {
          display: none;
        }
      }
    }
  }
`;

export default {
  AlternatingPanels,
  Panel,
};
