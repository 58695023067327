import styled from 'styled-components';

const TwoColumnContent = styled.div`
    position: relative;
    display: flex;
    overflow: hidden;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
        flex-direction: column;
    }
`

const Column = styled.div`
    position: relative;
    width: 50%;
    padding-right: 40px;

    &:nth-of-type(even) {
        padding-left: 40px;
        padding-right: 0;

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
            padding-left: 0;
            margin-top: 20px;
            width: 100%;
        }
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
        padding-right: 0;
    }
`

export default {
    TwoColumnContent,
    Column
}