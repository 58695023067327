import React from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { contentfulRichTextLinksWithArrow } from "../../../../utils/helpers";
import S from "./styles";
import { ContentfulLinksContentContentColumnSection } from '../../../../types';

export interface LinksContentProps extends ContentfulLinksContentContentColumnSection {
  [prop: string]: any;
}

const LinksContent = ({ linksContent, colourScheme, pathContext, ...rest }: LinksContentProps) => {
  const { pageMap } = pathContext;

  return (
    <S.LinksContent colourScheme={colourScheme} {...rest}>
      {documentToReactComponents(linksContent.json, {
        renderNode: {
          ...contentfulRichTextLinksWithArrow({ pageMap, colourScheme }),
        },
      })}
    </S.LinksContent>
  );
};

export default LinksContent;
