import styled from 'styled-components';
import {FeaturedClientExperienceProps} from './'

const FeaturedClientExperience = styled.section<FeaturedClientExperienceProps>`
    position: relative;
    padding: 140px 0 35px 0;
    background-color: ${({ theme, colourScheme }) => colourScheme === "dark" ? theme.colors.darkGrey1 : theme.colors.white1};

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
        padding-top: 100px;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        padding-top: 80px;
    }

    .inner {
        position: relative;
        display: flex;
        padding-bottom: 35px;
        border-bottom: 1px solid ${({ theme, colourScheme }) => colourScheme === "dark" ? theme.colors.white2 : theme.colors.mediumGrey1};

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
            flex-direction: column;
        }

        h3 {
            display: block;

            &.mobile {
                display: none;
            }

            @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
                display: none;
                margin-bottom: 40px;

                &.mobile {
                    display: block;
                }
            }
        }

        .gatsby-image-wrapper {
            max-width: 760px;
            width: 100%;
            flex-grow: 1;

            @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
                margin-top: 50px;
            }
        }

        .content {
            flex: 1;
            padding-left: 70px;
            font-size: 18px;
            line-height: 29px;
            letter-spacing: 0.3px;
            color: ${({ theme, colourScheme }) => colourScheme === "dark" ? theme.colors.white1 : theme.colors.darkGrey1};

            @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
                padding-left: 0;
                font-size: 16px;
                line-height: 26px;
            }

            h4 {
                font-size: 24px;
                line-height: 36px;
                font-family: ${({ theme }) => theme.fonts.helveticaRegular};

                @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
                    font-size: 20px;
                    line-height: 30px;
                    margin-top: 0;
                }
            }

            h5 {
                font-size: 19px;
                line-height: 30px;
                font-family: ${({ theme }) => theme.fonts.rajdhaniSemiBold};
                text-transform: uppercase;
                letter-spacing: 1.8px;
                margin-top: 80px;

                @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
                    font-size: 18px;
                    line-height: 28px;
                    margin-top: 30px;
                    margin-bottom: 10px;
                }
            }

            a {
                margin-top: 35px;
            }
        }
    }
`

export default {
    FeaturedClientExperience
}