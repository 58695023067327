import styled from 'styled-components';
import { HeadingContentColumnsProps } from './'

const HeadingContentColumns = styled.section<HeadingContentColumnsProps>`
    position: relative;
    padding: 120px 0;
    background-color: ${({ colourScheme, theme}) => colourScheme === "dark" ? theme.colors.darkGrey1 : theme.colors.white1};
    color: ${({ colourScheme, theme}) => colourScheme === "dark" ? theme.colors.white1 : theme.colors.darkGrey1};

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
        padding: 90px 0;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        padding: 60px 0;
    }

    .inner {
        position: relative;
        display: flex;
        flex: 1;

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
            flex-direction: column;
        }

        .heading {
            position: relative;
            display: initial;
            flex: 1;

            @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
                margin-bottom: 30px;
            }
        }

        .content {
            position: relative;
            flex: 2;
            display: flex;
            flex-wrap: wrap;
            white-space: pre-wrap;
            font-size: 20px;
            line-height: 33px;
            overflow: hidden;

            @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
                font-size: 18px;
                line-height: 28px;
            }

            @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
                font-size: 16px;
                line-height: 26px;
            }

            h4 {
                margin-top: 0;
                margin-bottom: 30px;
                font-size: 46px;
                line-height: 52px;
                letter-spacing: -1px;

                @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
                    font-size: 30px;
                    line-height: 50px;
                    margin-bottom: 10px;
                }
            }

            h5 {
                margin-top: 0;
                font-family: ${({ theme }) => theme.fonts.helveticaRegular};
                font-size: 32px;
                line-height: 46px;
                letter-spacing: -0.6px;

                @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
                    font-size: 22px;
                    line-height: 32px;
                }
            }

            h6 {
                margin-top: 0;
                font-size: 24px;
                line-height: 36px;
                font-family: ${({ theme }) => theme.fonts.helveticaRegular};

                @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
                    font-size: 20px;
                    line-height: 30px;
                    margin-bottom: 30px;
                }
            }

            img {
                max-width: 100%;
            }
            
            p {
                position: relative;
                display: block;
                margin: 0;
                padding: 10px 0;

                &:first-of-type {
                    padding-top: 0;
                }

                &:last-of-type {
                    padding-bottom: 0;
                }
            }

            a {
                color: ${({ colourScheme, theme}) => colourScheme === "dark" ? theme.colors.white1 : theme.colors.darkGrey1};
            }

            b {
                font-family: ${({ theme }) => theme.fonts.poppinsSemiBold};
            }

            u {
                text-decoration: none;
                color: ${({ theme}) => theme.colors.green2};
            }

            ol {
                list-style: none;
                padding: 0;

                li {
                    &:after {
                        position: relative;
                        content: "";
                        display: inline-block;
                        top: -5px;
                        width: 30px;
                        height: 2px;
                        background-color: ${({ theme }) => theme.colors.green2};
                    }
                }
            }

            hr {
                margin: 60px 0;
                padding: 0;
                border: none;
                height: 1px;
                background-color: ${({ colourScheme, theme}) => colourScheme === "dark" ? theme.colors.white1 : theme.colors.mediumGrey1};
            }

            blockquote {
                position: relative;
                font-family: ${({ theme }) => theme.fonts.helveticaRegular};
                font-size: 32px;
                line-height: 46px;
                letter-spacing: -0.6px;
                padding: 0;
                margin: 80px 0;

                @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
                    font-size: 22px;
                    line-height: 32px;
                    margin-bottom: 30px;
                }

                svg {
                    position: absolute;
                    top: 8px;
                    left: -50px;

                    @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
                        position: relative;
                        left: 0;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
`

export default {
    HeadingContentColumns,
}