import React from "react";
import { graphql } from "gatsby";
import S from "./styles";

export const fragment = graphql`
  fragment ContentSpacer on ContentfulContentSpacer {
    id
    internal {
      type
    }
    mobileSpace
    desktopSpace
  }
`;

export interface ContentSpacerProps {
  [prop: string]: any;
}

const ContentSpacer = ({ ...rest }: ContentSpacerProps) => {
  return <S.ContentSpacer {...rest} />;
};

export default ContentSpacer;
