import React, { useState } from "react";
import { graphql } from "gatsby";
import Image from "gatsby-image";
import { useTheme } from 'styled-components';
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Container from "../../layout/Container";
import UnderlineHeading from "../../common/UnderlineHeading";
import ArrowIcon from "../../common/ArrowIcon";
import { getSlugByID } from "../../../utils/helpers";
import S from "./styles";
import { ContentfulImageTitleContentSelector } from '../../../types';

export const fragment = graphql`
  fragment ImageTitleContentSelector on ContentfulImageTitleContentSelector {
    id
    internal {
      type
    }
    heading
    initialBackground {
      fluid(quality: 80, maxWidth: 1600) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    selections {
      title {
        title
      }
      backgroundImage {
        fluid(quality: 80, maxWidth: 1600) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      contentLink {
        contentful_id
      }
    }
  }
`;

export interface ImageTitleContentSelectorProps extends ContentfulImageTitleContentSelector {
  [prop: string]: any;
}

const ImageTitleContentSelector = ({
  heading,
  initialBackground,
  selections,
  pathContext,
  ...rest
}: ImageTitleContentSelectorProps) => {
  if (!selections || !selections.length) return null;

  const [selected, setSelected] = useState(null);
  const { pageMap } = pathContext;
  const theme = useTheme();

  return (
    <S.ImageTitleContentSelector
      {...rest}
      onMouseOver={() => setSelected(null)}
    >
      <Image
        className="initial"
        fluid={initialBackground.fluid}
        style={{ position: "absolute", zIndex: 1 }}
      />
      {selections.map((selection, index) => (
        <Image
          key={index}
          className={`selection-image ${selected === index ? "selected" : ""}`}
          fluid={selection.backgroundImage.fluid}
          style={{
            position: "absolute",
            zIndex: 2,
          }}
        />
      ))}
      <Container>
        <div className="inner">
          {heading && (
            <UnderlineHeading colourScheme="dark">{heading}</UnderlineHeading>
          )}
          <div className="selections">
            {selections.map((selection, index) => (
              <AniLink
                cover
                key={selection.contentLink.contentful_id}
                direction="up"
                bg={theme.colors.darkGrey1}
                to={getSlugByID({
                  pageMap,
                  pageID: selection.contentLink.contentful_id,
                })}
              >
                <h4
                  onMouseOver={e => {
                    e.stopPropagation();
                    setSelected(index);
                  }}
                >
                  {selection.title.title}
                  <ArrowIcon colourScheme="dark" />
                </h4>
              </AniLink>
            ))}
          </div>
        </div>
      </Container>
    </S.ImageTitleContentSelector>
  );
};

export default ImageTitleContentSelector;
