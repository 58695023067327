import React from "react";
import Image from "gatsby-image";
import S from "./styles";
import { ContentfulTeamMember } from '../../../types'

export interface MemberProps extends ContentfulTeamMember {
  [prop: string]: any;
}

const Member = ({
  index,
  image,
  name,
  position,
  bio,
  statement,
  linkedinUrl,
  selectedBio,
  setSelectedBio,
  first,
  last,
  ...rest
}: MemberProps) => {
  return (
    <S.Member {...rest}>
      <Image fluid={image.fluid} />
      <h4>{name}</h4>
      <h5>{position}</h5>
    </S.Member>
  );
};

export default Member;
