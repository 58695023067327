import styled from "styled-components";

const JobAdderWidget = styled.section`
  position: relative;

  .section-title {
    text-align: left;
    margin-bottom: 40px;
  }

  .ja-form {
    display: flex;
    margin-bottom: 20px;
    justify-content: space-evenly;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
      flex-direction: column;
    }

    &:before {
      display: none;
    }

    .ja-field-container {
      flex: 1;
      margin: 0;
      padding-right: 20px;

      &:last-child {
        padding-right: 0;
      }

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        margin-bottom: 20px;
        padding-right: 0;
      }

      label {
        font-family: ${({ theme }) => theme.fonts.rajdhaniSemiBold};
        text-transform: uppercase;
        letter-spacing: 1px;
      }

      .ja-field {
        width: 100%;

        input,
        select {
          border-radius: 0;
          height: 44px;
        }

        * {
          width: 100%;
        }
      }
    }

    .ja-submit {
      margin-top: 22px;
      color: ${({ theme }) => theme.colors.white};

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        margin-top: 0px;
        margin-bottom: 40px;
      }

      input {
        background-color: ${({ theme }) => theme.colors.green1};
        color: ${({ theme }) => theme.colors.white1};
        border-radius: 0;
        border: none;
        text-transform: uppercase;
        font-family: ${({ theme }) => theme.fonts.rajdhaniSemiBold};
        font-size: 18px;
        letter-spacing: 1px;
      }
    }
  }

  .job,
  .ja-job-details {
    .title {
      font-size: 20px;
      color: ${({ theme }) => theme.colors.darkGrey1} !important;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        font-size: 18px;
        line-height: 20px;
      }
    }

    .meta {
      @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        display: flex;
        flex-direction: column;

        .classifications {
          order: 2;
          width: 100%;
        }
      }

      .classifications {
        padding-left: 0;

        li {
          font-size: 18px;
          line-height: 28px;

          @media screen and (max-width: ${({ theme }) =>
              theme.breakpoints.small}) {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }

      .date-posted {
        color: ${({ theme }) => theme.colors.mediumGrey2};
        font-size: 18px;
        margin-top: 0;

        @media screen and (max-width: ${({ theme }) =>
            theme.breakpoints.small}) {
          font-size: 16px;
          text-align: left;
          margin-bottom: 20px;
          width: 100%;
        }
      }
    }

    .bullet-points {
      padding-left: 0;

      li {
        font-size: 18px;
        line-height: 28px;

        @media screen and (max-width: ${({ theme }) =>
            theme.breakpoints.small}) {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }

    .summary,
    .description {
      font-size: 18px;
      line-height: 28px;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        font-size: 16px;
        line-height: 24px;
      }
    }

    .view-details {
      font-family: ${({ theme }) => theme.fonts.rajdhaniSemiBold};
      text-transform: uppercase;
      font-size: 18px;
      background: none;
      border-radius: 0;
      border: 1px solid ${({ theme }) => theme.colors.darkGrey1};
      color: ${({ theme }) => theme.colors.darkGrey1};
      padding: 10px 20px;
      text-shadow: none;
      box-shadow: none;
      letter-spacing: 1px;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        font-size: 16px;
        line-height: 24px;
      }

      &:hover {
        background: ${({ theme }) => theme.colors.darkGrey1};
        color: ${({ theme }) => theme.colors.white1};
      }
    }

    .ja-button {
      font-family: ${({ theme }) => theme.fonts.rajdhaniSemiBold};
      text-transform: uppercase;
      font-size: 18px;
      background: ${({ theme }) => theme.colors.green1};
      border-radius: 0;
      border: 1px solid ${({ theme }) => theme.colors.darkGrey1};
      color: ${({ theme }) => theme.colors.white1};
      padding: 20px 40px;
      text-shadow: none;
      box-shadow: none;
      letter-spacing: 1px;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  .ja-pager {
    a,
    span {
      background: none;
      border-radius: 0;
      border-color: ${({ theme }) => theme.colors.darkGrey1};
    }

    a {
      text-decoration: none;

      &:hover {
        background: ${({ theme }) => theme.colors.darkGrey1};
      }
    }

    span {
      border-color: ${({ theme }) => theme.colors.mediumGrey2};
    }
  }
`;

export default {
  JobAdderWidget,
};
