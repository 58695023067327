import React, { useEffect, useContext } from "react";
import { graphql } from "gatsby";
import Container from "../../layout/Container";
import S from "./styles";
import { HeaderContext } from "../../../context/HeaderContext";
import { ContentfulSimplePageHeader } from '../../../types';
import AnimatedHeading from "../../common/AnimatedHeading";

export const fragment = graphql`
  fragment SimplePageHeader on ContentfulSimplePageHeader {
    id
    internal {
      type
    }
    colourScheme
    simpleHeading: heading {
      heading
    }
  }
`;

export interface SimplePageHeaderProps extends ContentfulSimplePageHeader {
  [prop: string]: any;
}

const SimplePageHeader = ({ colourScheme, simpleHeading, ...rest }: SimplePageHeaderProps) => {
  const { setHeaderColor, setInitialHeaderColor } = useContext(HeaderContext);

  useEffect(() => {
    const scheme = colourScheme === "dark" ? "light" : "dark";
    setHeaderColor(scheme);
    setInitialHeaderColor(scheme);
  }, []);

  return (
    <S.SimplePageHeader colourScheme={colourScheme} {...rest}>
      {simpleHeading && (
        <Container>
          <div className="content">
            <h1><AnimatedHeading colourScheme={colourScheme === "dark" ? "light" : "dark"}>{simpleHeading.heading}</AnimatedHeading></h1>
          </div>
        </Container>
      )}
    </S.SimplePageHeader>
  );
};

export default SimplePageHeader;
