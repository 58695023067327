import React from "react";
import { graphql } from "gatsby";
import Image from 'gatsby-image';
import Container from "../../layout/Container";
import UnderlineHeading from "../../common/UnderlineHeading";
import S from "./styles";
import { ContentfulClients } from '../../../types';

export const fragment = graphql`
  fragment Clients on ContentfulClients {
    id
    internal {
      type
    }
    colourScheme
    columns
    clientsHeading: heading {
      heading
    }
    logos {
      fluid(quality: 80, maxWidth: 500) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
  }
`;

export interface ClientsProps extends ContentfulClients {
  [prop: string]: any;
}

const Clients = ({
  colourScheme,
  columns,
  clientsHeading,
  logos,
  ...rest
}: ClientsProps) => {
  return (
    <S.Clients colourScheme={colourScheme} columns={columns} {...rest}>
      <Container>
        <div className="inner">
          {clientsHeading && (
            <UnderlineHeading colourScheme={colourScheme}>
              {clientsHeading.heading}
            </UnderlineHeading>
          )}
          <div className="clients">
            {logos.map(logo => <S.Client columns={columns}><Image fluid={logo.fluid} /></S.Client>)}
          </div>
        </div>
      </Container>
    </S.Clients>
  );
};

export default Clients;
