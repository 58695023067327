import styled from 'styled-components';

const IconFeatures = styled.section`
    position: relative;
    padding: 120px 0;
    background-color: ${({ theme, colourScheme}) => colourScheme === 'dark' ? theme.colors.darkGrey1 : theme.colors.white1};

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
        padding: 60px 0;
    }

    .features {
        display: flex;
        padding-top: 100px;
        justify-content: center;

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
            flex-direction: column;
            padding-top: 60px;
        }
    }
`

const Feature = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 33.3333%;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
        padding-left: 10px;
        padding-right: 10px;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
        width: 100%;
        margin-bottom: 50px;
        padding: 0;
    }

    h4 {
        font-family: ${({ theme }) => theme.fonts.rajdhaniSemiBold};
        color: ${({ theme, colourScheme }) => colourScheme === "dark" ? theme.colors.white2 : theme.colors.darkGrey1};
        white-space: pre-wrap;
        text-align: center;
        text-transform: uppercase;
        font-size: 19px;
        line-height: 30px;
        letter-spacing: 1.8px;
    }
`

export default {
    IconFeatures,
    Feature
}