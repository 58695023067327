import React, { useState } from "react";
import { graphql } from "gatsby";
import Image from "gatsby-image";
import TransitionLink from "gatsby-plugin-transition-link";
import Container from "../../layout/Container";
import UnderlineHeading from "../../common/UnderlineHeading";
import ArrowIcon from "../../common/ArrowIcon";
import { getSlugByID } from "../../../utils/helpers";
import S from "./styles";
import { ContentfulImageTitleTiles } from '../../../types';

export const fragment = graphql`
  fragment ImageTitleTiles on ContentfulImageTitleTiles {
    id
    internal {
      type
    }
    colourScheme
    ittHeading: heading {
      heading
    }
    tiles {
      title {
        title
      }
      image {
        fluid(quality: 80, maxWidth: 1000) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      link {
        contentful_id
      }
    }
  }
`;

export interface ImageTitleTilesProps extends ContentfulImageTitleTiles {
  [prop: string]: any;
}

const ImageTitleTiles = ({
  colourScheme,
  ittHeading,
  tiles,
  pathContext,
  ...rest
}: ImageTitleTilesProps) => {
  if (!tiles || !tiles.length) return null;

  const { pageMap } = pathContext;

  return (
    <S.ImageTitleTiles colourScheme={colourScheme} {...rest}>
      <Container>
        {ittHeading && (
          <UnderlineHeading colourScheme={colourScheme}>
            {ittHeading.heading}
          </UnderlineHeading>
        )}
        <div className="tiles">
          {tiles.map((tile) => (
            <S.Tile colourScheme={colourScheme}>
              <TransitionLink
                to={getSlugByID({ pageMap, pageID: tile.link.contentful_id })}
              >
                <Image fluid={tile.image.fluid} />
                <div className="title">
                  <h4>{tile.title.title}</h4>
                  <ArrowIcon colourScheme={colourScheme} />
                </div>
              </TransitionLink>
            </S.Tile>
          ))}
        </div>
      </Container>
    </S.ImageTitleTiles>
  );
};

export default ImageTitleTiles;
