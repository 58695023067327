import React, { useState, useEffect, useContext, useRef } from "react";
import { graphql } from "gatsby";
import GatsbyImage from "gatsby-image";
import Container from "../../layout/Container";
import S from "./styles";
import { HeaderContext } from "../../../context/HeaderContext";
import { ContentfulPageHeader } from "../../../types";
import AnimatedHeading from "../../common/AnimatedHeading";

export const fragment = graphql`
  fragment PageHeader on ContentfulPageHeader {
    id
    internal {
      type
    }
    headerHeading: heading {
      heading
    }
    backgroundType
    backgroundImage {
      title
      fluid(quality: 80, maxWidth: 1600) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    backgroundVideoTint
    backgroundVideo {
      file {
        url
      }
    }
    backgroundVideoWebm {
      file {
        url
      }
    }
  }
`;

export interface PageHeaderProps extends ContentfulPageHeader {
  [prop: string]: any;
}

const PageHeader = ({
  headerHeading,
  backgroundType,
  backgroundColour,
  backgroundVideo,
  backgroundVideoWebm,
  backgroundVideoTint,
  backgroundImage,
  ...rest
}: PageHeaderProps) => {
  const { setHeaderColor, setInitialHeaderColor } = useContext(HeaderContext);

  useEffect(() => {
    setHeaderColor("dark");
    setInitialHeaderColor("dark");
  }, []);

  return (
    <S.PageHeader backgroundColour={backgroundColour} {...rest}>
      {headerHeading && (
        <Container>
          <div className="content">
            <h1>
              <AnimatedHeading colourScheme="dark">
                {headerHeading.heading}
              </AnimatedHeading>
            </h1>
          </div>
        </Container>
      )}
      {backgroundType === "image" && (
        <GatsbyImage
          fluid={backgroundImage.fluid}
          alt={backgroundImage.title}
          style={{
            position: "absolute",
          }}
        />
      )}
      {backgroundType === "video" && (backgroundVideo || backgroundVideoWebm) && (
        <div className={`video-wrapper ${backgroundVideoTint ? "tint" : ""}`}>
          <video autoPlay muted playsInline loop>
            {backgroundVideo && (
              <source src={backgroundVideo.file.url} type="video/mp4" />
            )}
            {backgroundVideoWebm && (
              <source src={backgroundVideoWebm.file.url} type="video/webm" />
            )}
          </video>
        </div>
      )}
    </S.PageHeader>
  );
};

export default PageHeader;
