import React, { useState } from "react";
import axios from 'axios';
import ArrowIcon from "../../common/ArrowIcon";
import S from "./styles";
import SpinnerIcon from "../../common/SpinnerIcon";

export interface ContactFormProps {
  endpoint: string;
  [prop: string]: any;
}

const ContactForm = ({ endpoint, ...rest }: ContactFormProps) => {
  const [fields, setFields] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [submitting, setSubmitting] = useState(false);
  const [message, setMessage] = useState("");

  const handleSubmit = e => {
    
    e.preventDefault();

    if(fields.name && fields.email && fields.subject && fields.message) {
      setMessage("");
      setSubmitting(true);

      axios.post(endpoint, {
        name: fields.name,
        email: fields.email,
        subject: fields.subject,
        message: fields.message
      }, {
        headers: {
          "Accept": "application/json"
        }
      }).then(res => {
        setSubmitting(false);
        setMessage("Thank you for contacting JVAT - we will get back to you as soon as possible");
        setFields({
          name: "",
          email: "",
          subject: "",
          message: "",
        });
      }).catch(err => {
        setSubmitting(false);
        setMessage("There was an error sending your message")
      });
    } else {
      setMessage("Please ensure you've entered all required fields")
    }
  };

  return (
    <S.ContactForm onSubmit={e => handleSubmit(e)} {...rest}>
      <input
        type="text"
        name="name"
        required
        placeholder="Full Name*"
        value={fields.name}
        onChange={e => setFields({ ...fields, name: e.target.value })}
      />
      <input
        type="email"
        name="email"
        required
        placeholder="Email*"
        value={fields.email}
        onChange={e => setFields({ ...fields, email: e.target.value })}
      />
      <input
        type="text"
        name="subject"
        required
        placeholder="Subject*"
        value={fields.subject}
        onChange={e => setFields({ ...fields, subject: e.target.value })}
      />
      <div className="message">
        <label htmlFor="message">Message*</label>
        <textarea
          name="message"
          required
          value={fields.message}
          onChange={e => setFields({ ...fields, message: e.target.value })}
        ></textarea>
      </div>
      <button type="submit" disabled={submitting}>
        Submit
        <div className="icon">
          <ArrowIcon className="mobile" colourScheme="light" />
          <ArrowIcon className="arrow" colourScheme="dark" />
          <SpinnerIcon className="spinner" colourScheme="dark" />
        </div>
      </button>
      {message && <div className="form-message">{message}</div>}
    </S.ContactForm>
  );
};

export default ContactForm;
