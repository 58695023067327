import styled from 'styled-components';

const SimplePageHeader = styled.section`
    position: relative;
    height: 800px;
    background-color: ${({ theme }) => theme.colors.darkGrey1};

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
        padding-top: 75px;
        height: 530px;
    }

    .content {
        position: relative;
        display: flex;
        align-items: center;
        height: 100%;
        width: 66.6666%;
        margin-right: auto;

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
            width: 100%;
        }
    }

    h1 {
        position: relative;
        font-family: ${({ theme}) => theme.fonts.helveticaBold};
        text-transform: uppercase;
        font-size: 90px;
        line-height: 88px;
        color: ${({ theme}) => theme.colors.white1};
        letter-spacing: 0.7px;
        z-index: 2;
        margin: 0;
        white-space: pre-wrap;

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
            font-size: 40px;
            line-height: 42px;
            letter-spacing: -0.3px;
            white-space: normal;
        }
    }
`

export default {
    SimplePageHeader
}