import styled from 'styled-components';
import { animated } from 'react-spring';

const TeamSection = styled.section`
    position: relative;
    padding-bottom: 120px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
        padding-bottom: 60px;
    }
    
    h3 {
        font-family: ${({ theme }) => theme.fonts.helveticaRegular};
        font-size: 32px;
        line-height: 46px;
        letter-spacing: -0.6px;
        margin-top: 0;

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
            font-size: 26px;
            line-height: 38px;
            letter-spacing: -0.5px;
        }

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
            font-size: 22px;
            line-height: 32px;
            letter-spacing: -0.4px;
        }
    }

    .members {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -20px;

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
            flex-wrap: nowrap;
            overflow-x: scroll;
        }
    }

    .overlay-wrapper {
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0);
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 1000;
        transition: background-color 0.5s ease;
        pointer-events: none;

        &.open {
            pointer-events: all;
            background-color: rgba(0,0,0,0.75);
        }
    }
`

const Member = styled.div`
    position: relative;
    padding: 0 20px;
    width: 33.3333%;
    cursor: pointer;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
        width: 50%;
        min-width: 50%;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        width: 80%;
        min-width: 80%;
    }

    .gatsby-image-wrapper {
        max-height: 360px;
        max-width: 360px;
        object-fit: cover;

        img {
            width: 100%;
        }
    }

    h4 {
        font-family: ${({ theme }) => theme.fonts.poppinsSemiBold};
        font-size: 20px;
        line-height: 33px;
        margin-bottom: 0;
    }

    h5 {
        font-family: ${({ theme }) => theme.fonts.poppinsRegular};
        font-size: 20px;
        line-height: 33px;
        margin-top: 0;
    }

   
`

const BioOverlay = styled(animated.div)`
    position: absolute;
    width: 66.6666%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.white1};
    transform: translateX(100%);
    right: 0;
    z-index: 10000;
    cursor: default;
    opacity: 1;
    overflow-y: scroll;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
        width: 100%;
    }

    .close {
        position: absolute;
        top: 35px;
        right: 35px;
        background: transparent;
        border: none;
        outline: none;
        cursor: pointer;
        z-index: 9999;

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
            top: 20px;
            right: 30px;

            svg {
                width: 30px;
                height: 30px;
            }
        }
    }

    .inner {
        position: absolute;
        z-index: 5000;
        opacity: 0;
        width: 100%;
        top: 0;
        left: 0;
        pointer-events: none;
        padding: 100px 100px 100px 50px;

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
            padding: 70px 30px;
        }
        
        &.selected {
            position: relative;
            opacity: 1;
            pointer-events: all;
        }
        
    }

    .navigation {
        position: sticky;
        padding: 15px 35px;
        bottom: 0px;
        width: 100%;
        text-align: right;
        z-index: 9999;
        background-color: white;
        border-top: 1px solid ${({ theme }) => theme.colors.lightGrey1};

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
            padding: 15px;
            display: flex;
            justify-content: space-between;
        }

        &:before {
            position: absolute;
            content: "";
            width: 100%;
            top: -101px;
            left: 0;
            height: 100px;
            background: rgb(255,255,255);
            background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
            
            @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
                display: none;
            }
        }

        button {
            background: transparent;
            border: none;
            cursor: pointer;
            outline: none;

            &:disabled {
                opacity: 0.25;
            }
        }
    }

    .details {
        display: flex;

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
            flex-direction: column;
        }

        .gatsby-image-wrapper {
            position: relative;
            width: 360px !important;
            height: 360px !important;

            @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
                width: 100% !important;
                height: 100% !important;
            }

            img {
                object-fit: cover;
                object-position: center;
            }
            
        }

        .name-position {
            margin-left: 45px;

            @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
                margin-left: 0;
                margin-top: 25px;
            }
        }

        h4 {
            font-family: ${({ theme }) => theme.fonts.poppinsSemiBold};
            font-size: 20px;
            line-height: 33px;
            margin-bottom: 0;
            margin-top: 0;

            @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
                font-size: 16px;
                line-height: 26px;
            }
        }

        h5 {
            font-family: ${({ theme }) => theme.fonts.poppinsRegular};
            font-size: 20px;
            line-height: 33px;
            margin-top: 0;

            @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
                font-size: 16px;
                line-height: 26px;
                margin-bottom: 0;
            }
        }
    }

    .bio {
        position: relative;
        margin-top: 30px;
        font-size: 20px;
        font-family: ${({ theme }) => theme.fonts.poppinsRegular};
        line-height: 33px;

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
            font-size: 16px;
            line-height: 26px;
        }
    }

    .quotation-marks {
        margin-top: 40px;

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
            width: 27px;
            height: 27px;
        }
    }

    .statement {
        position: relative;
        margin: 20px 0 30px 0;
        font-size: 24px;
        font-family: ${({ theme }) => theme.fonts.poppinsRegular};
        line-height: 36px;

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
            font-size: 20px;
            line-height: 30px;
        }
    }
`

export default {
    TeamSection,
    Member,
    BioOverlay
}