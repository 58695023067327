import AlternatingPanels from "../components/content/AlternatingPanels";
import ArticlesHome from "../components/content/ArticlesHome";
import Awards from "../components/content/Awards";
import ClientExperiencesHome from "../components/content/ClientExperiencesHome";
import Clients from "../components/content/Clients";
import ContactContent from "../components/content/ContactContent";
import ContentSpacer from "../components/content/ContentSpacer";
import ExcerptColumns from "../components/content/ExcerptColumns";
import FeaturedClientExperience from "../components/content/FeaturedClientExperience";
import FeaturedContent from "../components/content/FeaturedContent";
import HeadingContentColumns from "../components/content/HeadingContentColumns";
import IconFeatures from "../components/content/IconFeatures";
import ImageTitleContentSelector from "../components/content/ImageTitleContentSelector";
import ImageTitleTiles from "../components/content/ImageTitleTiles";
import IntroContent from "../components/content/IntroContent";
import JobAdderWidget from "../components/content/JobAdderWidget";
import PageHeader from "../components/content/PageHeader";
import SimplePageHeader from "../components/content/SimplePageHeader";
import TeamSection from "../components/content/TeamSection";
import UnderlinedHeading from "../components/content/UnderlinedHeading";

export default {
  ContentfulAlternatingPanels: AlternatingPanels,
  ContentfulArticlesSettings: ArticlesHome,
  ContentfulAwards: Awards,
  ContentfulClientExperiencesSettings: ClientExperiencesHome,
  ContentfulClients: Clients,
  ContentfulContactSettings: ContactContent,
  ContentfulContentSpacer: ContentSpacer,
  ContentfulExcerptColumns: ExcerptColumns,
  ContentfulFeaturedClientExperience: FeaturedClientExperience,
  ContentfulFeaturedContent: FeaturedContent,
  ContentfulHeadingContentColumns: HeadingContentColumns,
  ContentfulIconFeatures: IconFeatures,
  ContentfulImageTitleContentSelector: ImageTitleContentSelector,
  ContentfulImageTitleTiles: ImageTitleTiles,
  ContentfulIntroContent: IntroContent,
  ContentfulJobAdderWidget: JobAdderWidget,
  ContentfulPageHeader: PageHeader,
  ContentfulSimplePageHeader: SimplePageHeader,
  ContentfulTeamSection: TeamSection,
  ContentfulUnderlinedHeading: UnderlinedHeading,
};
