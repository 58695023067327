import styled from "styled-components";

const IntroContent = styled.section`
  position: relative;
  padding: 120px 0;
  background-color: ${({ theme, colourScheme }) =>
    colourScheme === "dark" ? theme.colors.darkGrey1 : theme.colors.white1};
  color: ${({ theme, colourScheme }) =>
    colourScheme === "dark" ? theme.colors.white2 : theme.colors.darkGrey1};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding: 90px 0;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    padding: 60px 0;
  }

  .inner {
    display: flex;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
      flex-direction: column;
    }

    img {
      position: relative;
      max-width: 120px;
      height: auto;
      justify-self: flex-start;
      align-self: flex-start;

      @media screen and (max-width: ${({ theme }) =>
          theme.breakpoints.medium}) {
        margin-bottom: 35px;
        max-width: 90px;
      }
    }
  }

  .content {
    position: relative;
    width: 66.6666%;
    margin-left: auto;
    background-color: ${({ theme }) => theme.fonts.helveticaRegular};
    font-size: 32px;
    line-height: 46px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
      font-size: 22px;
      line-height: 32px;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
      width: 100%;
    }

    p {
      margin: 0;
      padding: 10px 0;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }

    i {
      font-size: 20px;
      line-height: 33px;
      letter-spacing: -0.2px;
      text-decoration: none;
      margin: 0;
      display: block;
      font-style: normal;
    }

    .read-more {
      margin-top: 20px;
    }
  }
`;

export default {
  IntroContent,
};
