import styled from 'styled-components';

const ContactForm = styled.form`
    position: relative;
    display: flex;
    flex-direction: column;
    
    input,
    textarea {
        background-color: transparent;
        font-family: ${({ theme }) => theme.fonts.poppinsRegular};
        color: ${({ theme }) => theme.colors.white2};
        font-size: 20px;
        line-height: 55px;

        &::placeholder {
            color: ${({ theme }) => theme.colors.white2};
        }
    }

    input {
        border: none;
        border-bottom: 1px solid ${({ theme }) => theme.colors.white2};
        margin-bottom: 60px;
        border-radius: 0;
    }

    textarea {
        border: 1px solid ${({ theme }) => theme.colors.white2};
        padding: 0 20px;
        height: 200px;
    }

    .message {
        display: flex;
        flex-direction: column;

        label {
            font-family: ${({ theme }) => theme.fonts.poppinsRegular};
            color: ${({ theme }) => theme.colors.white2};
            font-size: 20px;
            line-height: 55px;
        }
    }

    .subscribe {
        padding: 40px 0;
        font-family: ${({ theme }) => theme.fonts.poppinsRegular};
        color: ${({ theme }) => theme.colors.white2};
        font-size: 20px;
        line-height: 33px;
        
        label {
            display: flex;
            align-items: center;
            position: relative;
            padding-left: 50px;
            margin-bottom: 12px;
            cursor: pointer;
            font-size: 22px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;

            &:hover {
                input ~ .checkmark {
                    background-color: #ccc;
                }
            }

            input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;

                &:checked ~ .checkmark {
                    background-color: ${({ theme }) => theme.colors.green1};
                }

                &:checked ~ .checkmark:after {
                    display: block;
                }
            }

            .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 31px;
                width: 31px;
                background-color: #eee;

                &:after {
                    content: "";
                    position: absolute;
                    display: none;
                    left: 9px;
                    top: 5px;
                    width: 7px;
                    height: 15px;
                    border: solid white;
                    border-width: 0 3px 3px 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
            }
        }
    }

    button {
        background: none;
        border: 1px solid ${({ theme }) => theme.colors.white2};
        color: ${({ theme }) => theme.colors.white2};
        align-self: flex-start;
        display: flex;
        align-items: center;
        font-family: ${({ theme }) => theme.fonts.rajdhaniSemiBold};
        text-transform: uppercase;
        padding: 18px;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: 2.3px;
        cursor: pointer;
        margin-top: 40px;

        .icon {
            margin-left: 80px;
        }

        .arrow {
            display: block;
        }

        .spinner {
            display: none;
        }

        &:disabled {
            .arrow,
            .mobile {
                display: none;
            }

            .spinner {
                display: block;
            }
        }

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border: none;
            color: ${({ theme }) => theme.colors.darkGrey1};
            background-color: ${({ theme }) => theme.colors.green1};

            .arrow {
                display: none;
            }
        }

        svg {
            width: 23px;
            height: 23px;
            

            &.mobile {
                display: none;
            }

            @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
                margin-left: 0;
                display: none;

                &.mobile {
                    display: block;
                }
            }
        }
    }

    .form-message {
        position: absolute;
        color: ${({ theme }) => theme.colors.white1};
        bottom: -60px;
    }
`

export default {
    ContactForm
}