import React, { useState, useEffect, useRef, useContext } from "react";
import Image from "gatsby-image";
import { graphql } from "gatsby";
import { useSpring } from "react-spring";
import { useTheme} from 'styled-components';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Member from "./Member";
import Container from "../../layout/Container";
import S from "./styles";
import ArrowIcon from "../../common/ArrowIcon";
import LinkedinIcon from "../../common/LinkedinIcon";
import QuotationMarksIcon from '../../common/QuotationMarksIcon';
import CloseIcon from "../../common/CloseIcon";
import { HeaderContext } from "../../../context/HeaderContext";
import { ContentfulTeamSection } from '../../../types';

export const fragment = graphql`
  fragment TeamSection on ContentfulTeamSection {
    id
    internal {
      type
    }
    title
    teamMembers {
      id
      image {
        fluid(quality: 80, maxWidth: 720) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      name
      position
      bio {
        json
      }
      statement {
        statement
      }
      linkedinUrl
    }
  }
`;

export interface TeamSectionProps extends ContentfulTeamSection {
  [prop: string]: any;
}

const TeamSection = ({ title, teamMembers, ...rest }: TeamSectionProps) => {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedBio, setSelectedBio] = useState(teamMembers[0]);
  const overlayRef = useRef();
  const theme = useTheme();
  const springProps = useSpring({
    transform: selectedIndex !== null ? "translateX(0%)" : "translateX(100%)",
  });
  const { setShowHeader } = useContext(HeaderContext);

  useEffect(() => {
    if (selectedIndex !== null) {
      setSelectedBio(teamMembers[selectedIndex]);
      document.body.classList.add("fixed");
      setShowHeader(false);
    } else {
      document.body.classList.remove("fixed");
    }
  }, [selectedIndex]);

  return (
    <S.TeamSection {...rest}>
      <Container>
        <div className="inner">
          {title && <h3>{title}</h3>}
          <div className="members">
            {teamMembers.map((member, index) => (
              <Member
                {...member}
                key={index}
                index={index}
                onClick={() => {
                  setSelectedIndex(index);
                  overlayRef.current.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                  });
                }}
              />
            ))}
          </div>
        </div>
        <div
          className={`overlay-wrapper ${selectedIndex !== null ? "open" : ""}`}
          onClick={e => {
            if (selectedBio !== null) {
              e.stopPropagation();
              setSelectedIndex(null);
            }
          }}
        >
          <S.BioOverlay
            style={springProps}
            onClick={e => e.stopPropagation()}
            ref={overlayRef}
          >
            <button className="close" onClick={() => setSelectedIndex(null)}>
              <CloseIcon />
            </button>
            {teamMembers.map((member, index) => (
              <div
                className={`inner ${selectedIndex === index ? 'selected' : ''}`}
                key={index}
              >
                <div className="details">
                  <Image fluid={member.image.fluid} />
                  <div className="name-position">
                    <h4>{member.name}</h4>
                    <h5>{member.position}</h5>
                  </div>
                </div>
                <div className="bio">
                  {documentToReactComponents(member.bio.json)}
                </div>
                <QuotationMarksIcon className="quotation-marks" colourScheme="light" />
                <div className="statement">{member.statement.statement}</div>
                {member.linkedinUrl && (
                  <a href={member.linkedinUrl}>
                    <LinkedinIcon colourScheme="light" />
                  </a>
                )}
              </div>
            ))}
            <div className="navigation">
              <button
                onClick={() => {
                  setSelectedIndex(selectedIndex - 1);
                  overlayRef.current.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                  });
                }}
                disabled={selectedIndex === 0}
              >
                <ArrowIcon
                  color={theme.colors.green1}
                  style={{ transform: "rotate(180deg)" }}
                />
              </button>
              <button
                onClick={() => {
                  setSelectedIndex(selectedIndex + 1);
                  overlayRef.current.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                  });
                }}
                disabled={selectedIndex === teamMembers.length - 1}
              >
                <ArrowIcon color={theme.colors.green1} />
              </button>
            </div>
          </S.BioOverlay>
        </div>
      </Container>
    </S.TeamSection>
  );
};

export default TeamSection;
