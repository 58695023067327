import styled from 'styled-components';

const HalfContent = styled.div`
    position: relative;
    width: 50%;
    padding-right: 50px;
    padding-bottom: 50px;
    overflow: hidden;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
        width: 100%;
        padding-right: 0;
    }
`

export default {
    HalfContent
}