import styled from 'styled-components';

const LinksContent = styled.div`
    position: relative;
    overflow: hidden;
    flex: 1;
    margin: 40px 0;
    padding: 40px 0;
    background-color: ${({ theme, colourScheme }) => colourScheme === "dark" ? theme.colors.darkGrey1 : theme.colors.white1};
    border-top: 1px solid ${({ theme, colourScheme }) => colourScheme === "dark" ? theme.colors.white2 : theme.colors.mediumGrey1};
    border-bottom: 1px solid ${({ theme, colourScheme }) => colourScheme === "dark" ? theme.colors.white2 : theme.colors.mediumGrey1};
    color: ${({ theme, colourScheme }) => colourScheme === "dark" ? theme.colors.white2 : theme.colors.darkGrey1};

    a {
        font-family: ${({ theme }) => theme.fonts.rajdhaniSemiBold};
        text-decoration: none;
        text-transform: uppercase;
        font-size: 19px;
        line-height: 30px;
        letter-spacing: 1.8px;
        color: ${({ theme, colourScheme }) => colourScheme === "dark" ? theme.colors.white2 : theme.colors.darkGrey1};
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:hover {
            svg {
                transform: translateX(0);
            }
        }
        
        svg {
            transition: transform 0.2s ease;
            margin-left: 20px;
            transform: translateX(-20px);
            width: 40px;
            height: 40px;
        }
    }
`

export default {
    LinksContent,
}