import styled from 'styled-components';

const Clients = styled.section`
    position: relative;
    padding: 150px 0;
    background-color: ${({ theme, colourScheme }) => colourScheme === "dark" ? theme.colors.darkGrey1 : theme.colors.white1};

    .clients {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        margin: 30px auto 0 auto;
        max-width: ${({ columns }) => columns === 3 ? '860px' : '100%'};

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
            justify-content: space-between;
        }
    }
`

const Client = styled.div`
    position: relative;
    width: ${({ columns }) => columns === 3 ? '33.3333%' : '25%'};
    display: flex;
    justify-content: center;

    .gatsby-image-wrapper {
        width: 260px;
        height: auto;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        width: 45%;
    }
`

export default {
    Clients,
    Client
}