import React from "react";
import { graphql } from "gatsby";
import Sticky from 'react-stickynode';
import Container from "../../layout/Container";
import BasicContent from "./BasicContent";
import HalfContent from "./HalfContent";
import TwoColumnContent from "./TwoColumnContent";
import LinksContent from "./LinksContent";
import UnderlineHeading from "../../common/UnderlineHeading";
import S from "./styles";
import { ContentfulHeadingContentColumns } from "../../../types";

export const fragment = graphql`
  fragment HeadingContentColumns on ContentfulHeadingContentColumns {
    id
    internal {
      type
    }
    colourScheme
    stickyHeading
    columnHeading: heading {
      heading
    }
    columnContent: content {
      ...BasicContentContentColumnSection
      ...HalfContentContentColumnSection
      ...TwoColumnContentContentColumnSection
      ...LinksContentContentColumnSection
    }
  }

  fragment BasicContentContentColumnSection on ContentfulBasicContentContentColumnSection {
    id
    internal {
      type
    }
    basicContent: content {
      json
    }
  }

  fragment HalfContentContentColumnSection on ContentfulHalfContentContentColumnSection {
    id
    internal {
      type
    }
    halfContent: content {
      json
    }
  }

  fragment TwoColumnContentContentColumnSection on ContentfulTwoColumnContentContentColumnSection {
    id
    internal {
      type
    }
    twoColumnContent1: contentColumn1 {
      json
    }
    twoColumnContent2: contentColumn2 {
      json
    }
  }

  fragment LinksContentContentColumnSection on ContentfulLinksContentContentColumnSection {
    id
    internal {
      type
    }
    linksContent: content {
      json
    }
  }
`;

const contentMap = {
  ContentfulBasicContentContentColumnSection: BasicContent,
  ContentfulHalfContentContentColumnSection: HalfContent,
  ContentfulTwoColumnContentContentColumnSection: TwoColumnContent,
  ContentfulLinksContentContentColumnSection: LinksContent,
};

export interface HeadingContentColumnsProps
  extends ContentfulHeadingContentColumns {
  [prop: string]: any;
}

const HeadingContentColumns = ({
  id,
  colourScheme,
  stickyHeading,
  columnHeading,
  columnContent,
  pathContext,
  ...rest
}: HeadingContentColumnsProps) => {
  return (
    <S.HeadingContentColumns className="content-columns" colourScheme={colourScheme} {...rest}>
      <Container>
        <div className="inner">
          <div id={`sticky-${id}`} className="heading">
            {columnHeading && (
              <Sticky
                enabled={stickyHeading}
                top={100}
                bottomBoundary={`#sticky-${id}`}
              >
                <UnderlineHeading colourScheme={colourScheme}>
                  {columnHeading.heading}
                </UnderlineHeading>
              </Sticky>
            )}
          </div>
          <div className="content">
            {columnContent &&
              columnContent.map((component, index) => {
                const Component = contentMap[component?.internal?.type];

                if (!Component) return null;

                return (
                  <Component
                    key={index}
                    {...component}
                    colourScheme={colourScheme}
                    pathContext={pathContext}
                  />
                );
              })}
          </div>
        </div>
      </Container>
    </S.HeadingContentColumns>
  );
};

export default HeadingContentColumns;
