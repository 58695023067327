import styled from "styled-components";

const PageHeader = styled.section`
  position: relative;
  height: 800px;
  background-color: ${({ theme }) => theme.colors.darkGrey1};
  overflow: hidden;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding-top: 75px;
    height: 530px;
  }

  .video-wrapper {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    &.tint {
      &:after {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: ${({ theme }) => theme.colors.green1};
        opacity: 0.4;
        mix-blend-mode: multiply;
      }
    }

    video {
      position: absolute;
      width: 100%;
      max-height: 100%;
      height: 100%;
      top: 0;
      left: 0;
      bottom: 0;
      object-fit: cover;
      object-position: center;
    }
  }

  .gatsby-image-wrapper {
    z-index: 1;
    width: 100%;
    height: auto;
    max-height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    position: absolute;

    img {
      object-fit: cover;
      object-position: center;
      pointer-events: none;
    }
  }

  .content {
    position: relative;
    display: flex;
    align-items: flex-end;
    height: 100%;
    overflow: hidden;
    margin-right: auto;
    padding-bottom: 120px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
      width: 100%;
      padding-bottom: 60px;
    }
  }

  h1 {
    position: relative;
    font-family: ${({ theme }) => theme.fonts.helveticaBold};
    text-transform: uppercase;
    font-size: 90px;
    line-height: 88px;
    color: ${({ theme }) => theme.colors.white1};
    letter-spacing: 0.7px;
    z-index: 2;
    margin: 0;
    white-space: pre-wrap;

    ${({ theme }) => theme.mixins.headingAnimation("dark")}

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
      font-size: 60px;
      line-height: 62px;
      letter-spacing: -0.3px;
      white-space: normal;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
      font-size: 40px;
      line-height: 42px;
    }
  }
`;

export default {
  PageHeader,
};
