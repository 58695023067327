import React from "react";
import { graphql } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import {
  contentfulRichTextLinks,
  contentfulRichTextInlineImage,
} from "../../../utils/helpers";
import { getSlugByID } from "../../../utils/helpers";
import Container from "../../layout/Container";
import ReadMoreLink from "../../common/ReadMoreLink";
import S from "./styles";
import { ContentfulIntroContent } from "../../../types";

export const fragment = graphql`
  fragment IntroContent on ContentfulIntroContent {
    id
    internal {
      type
    }
    logo {
      title
      fixed {
        src
      }
    }
    colourScheme
    content {
      json
    }
    readMoreLink {
      contentful_id
    }
  }
`;

export interface IntroContentProps extends ContentfulIntroContent {
  [prop: string]: any;
}

const IntroContent = ({
  logo,
  colourScheme,
  content,
  readMoreLink,
  pathContext,
  ...rest
}: IntroContentProps) => {
  const { pageMap } = pathContext;

  return (
    <S.IntroContent colourScheme={colourScheme} {...rest}>
      <Container>
        <div className="inner">
          {logo && <img src={logo.fixed.src} alt={logo.title} />}
          <div className="content">
            {documentToReactComponents(content.json, {
              renderNode: {
                ...contentfulRichTextLinks({ pageMap }),
                ...contentfulRichTextInlineImage,
              },
            })}
            {readMoreLink && (
              <ReadMoreLink
                className="read-more"
                colourScheme={colourScheme}
                link={getSlugByID({
                  pageMap,
                  pageID: readMoreLink.contentful_id,
                })}
              />
            )}
          </div>
        </div>
      </Container>
    </S.IntroContent>
  );
};

export default IntroContent;
