import React from "react";
import { graphql } from "gatsby";
import GatsbyImage from "gatsby-image";
import Container from "../../layout/Container";
import S from "./styles";
import { ContentfulAlternatingPanels } from "../../../types";
import AnimatedText from "../../common/AnimatedText";

export const fragment = graphql`
  fragment AlternatingPanels on ContentfulAlternatingPanels {
    id
    internal {
      type
    }
    colourScheme
    panels {
      image {
        title
        fluid(quality: 80, maxWidth: 1000) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      content {
        content
      }
    }
  }
`;

export interface AlternatingPanelsProps extends ContentfulAlternatingPanels {
  pathContext?: any;
  [prop: string]: any;
}

export interface PanelProps extends AlternatingPanelsProps {}

const AlternatingPanels = ({
  colourScheme,
  panels,
  pathContext,
  ...rest
}: AlternatingPanelsProps) => {
  if (!panels) return null;

  return (
    <S.AlternatingPanels colourScheme={colourScheme} {...rest}>
      <Container>
        <div className="panels">
          {panels.map((panel, index) => (
            <S.Panel key={index} colourScheme={colourScheme}>
              <AnimatedText delay={0} className="image">
                <GatsbyImage
                  fluid={panel.image.fluid}
                  alt={panel.image.title}
                />
              </AnimatedText>
              <div className="content">
                {panel.content && (
                  <AnimatedText delay={0.5}>
                    <div className="inner">{panel.content.content}</div>
                  </AnimatedText>
                )}
              </div>
            </S.Panel>
          ))}
        </div>
      </Container>
    </S.AlternatingPanels>
  );
};

export default AlternatingPanels;
