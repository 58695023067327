import styled from 'styled-components';

const ClientExperiencesHome = styled.section`
    position: relative;
    padding: 300px 0;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
        padding: 240px 0;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        padding: 180px 0;
    }

    .heading {
        position: relative;
        border-bottom: 1px solid ${({ theme }) => theme.colors.darkGrey1};
        padding-bottom: 60px;
        
        h1 {
            font-family: ${({ theme }) => theme.fonts.helveticaBold};
            text-transform: uppercase;
            font-size: 90px;
            line-height: 88px;
            letter-spacing: -1.7px;
            white-space: pre-wrap;

            @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
                font-size: 50px;
                line-height: 50px;
            }

            @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
                font-size: 40px;
                line-height: 40px;
            }
        }
    }

    .intro {
        position: relative;
        padding: 100px 0;
        border-bottom: 1px solid ${({ theme }) => theme.colors.mediumGrey1};

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
            padding: 80px 0;
        }

        .inner {
            width: 66.6666%;
            margin-left: auto;
            font-size: 32px;
            line-height: 46px;
            letter-spacing: -0.6px;

            @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
                font-size: 26px;
                line-height: 36px;
            }

            @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
                width: 100%;
                font-size: 20px;
                line-height: 30px;
            }
        }
    }
`

export default {
    ClientExperiencesHome
}