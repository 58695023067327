import styled from "styled-components";

const ClientExperienceList = styled.div`
  position: relative;

  a {
    text-decoration: none;
  }

  .client-experience {
    position: relative;
    display: flex;
    align-items: stretch;

    padding: 30px 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.mediumGrey1};

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
      flex-direction: column;
    }

    .gatsby-image-wrapper {
      max-width: 360px;
      min-height: 280px;
      width: 100%;
      flex: 1;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        max-width: 100%;
      }

      img {
        object-fit: cover;
      }
    }

    .content {
      position: relative;
      flex: 1;
      padding-left: 40px;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        padding-left: 0;
      }

      h3 {
        font-family: ${({ theme }) => theme.fonts.helveticaRegular};
        font-size: 24px;
        line-height: 36px;

        @media screen and (max-width: ${({ theme }) =>
            theme.breakpoints.medium}) {
          font-size: 22px;
          line-height: 32px;
        }

        @media screen and (max-width: ${({ theme }) =>
            theme.breakpoints.small}) {
          font-size: 20px;
          line-height: 30px;
        }
      }

      h4 {
        font-family: ${({ theme }) => theme.fonts.rajdhaniSemiBold};
        text-transform: uppercase;
        letter-spacing: 1.8px;
        font-size: 18px;
        line-height: 28px;

        @media screen and (max-width: ${({ theme }) =>
            theme.breakpoints.medium}) {
          font-size: 16px;
          line-height: 26px;
        }
      }

      p {
        align-items: center;
        margin: 0;
        font-size: 20px;
        line-height: 33px;
        max-width: 560px;

        @media screen and (max-width: ${({ theme }) =>
            theme.breakpoints.medium}) {
          font-size: 18px;
          line-height: 28px;
        }

        @media screen and (max-width: ${({ theme }) =>
            theme.breakpoints.small}) {
          font-size: 16px;
          line-height: 26px;
        }
      }

      .read-more {
        display: flex;
        justify-content: space-between;
        display: none;
        position: relative;
        font-size: 19px;
        line-height: 30px;
        letter-spacing: 1.8px;
        font-family: ${({ theme }) => theme.fonts.rajdhaniSemiBold};
        margin-top: 30px;

        @media screen and (max-width: ${({ theme }) =>
            theme.breakpoints.small}) {
          display: flex;
        }

        svg {
          position: relative;
          display: block;
          width: 30px;
          height: 30px;
        }
      }
    }

    svg {
      position: absolute;
      right: 0;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        display: none;
      }
    }
  }
`;

export default {
  ClientExperienceList,
};
