import styled from 'styled-components';

const ImageTitleContentSelector = styled.section`
    position: relative;
    padding: 120px 0;
    background-color: ${({ theme }) => theme.colors.darkGrey1};
    display: flex;
    overflow: hidden;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
        padding: 60px 0;
    }

    .inner {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 100%;
        flex: 1;
    }

    .initial {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    .selection-image {
        transition: opacity 0.2s ease;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0;

        &.selected {
            opacity: 1;
        }
    }

    .selections {
        position: relative;

        a {
            text-decoration: none;
        }
    }

    h3 {
        margin-bottom: 50px;
    }

    h4 {
        position: relative;
        color: ${({ theme }) => theme.colors.white1};
        font-family: ${({ theme }) => theme.fonts.helveticaBold};
        font-size: 90px;
        line-height: 88px;
        text-transform: uppercase;
        white-space: pre-wrap;
        padding: 15px 0;
        margin: 0;
        letter-spacing: -0.7px;
        z-index: 2;
        opacity: 0.2;
        transition: opacity 0.2s ease;

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
            font-size: 50px;
            opacity: 1;
            line-height: 50px;
        }

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
            font-size: 34px;
            line-height: 34px;
            white-space: normal;
        }
        

        svg {
            position: absolute;
            top: calc(50% - 25px);
            opacity: 0;
            transition: all 0.2s ease;
            transform: translateX(80px);
            width: 50px;
            height: 50px;
            pointer-events: none;
        }

        &:hover {
            opacity: 1;

            svg {
                opacity: 1;
                transform: translateX(100px);
            }
        }
    }

  
`

export default {
    ImageTitleContentSelector
}