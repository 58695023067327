import styled from 'styled-components';

const Awards = styled.section`
    position: relative;
    padding: 80px 0 150px 0;
    background-color: ${({ theme, colourScheme }) => colourScheme === "dark" ? theme.colors.darkGrey1 : theme.colors.white1};

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        padding: 80px 0;
    }

    .inner {
        display: flex;
        align-items: flex-start;

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
            flex-direction: column;
        }

        h3 {
            flex: 1
        }
    }

    .awards {
        flex: 2;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        max-width: 860px;

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
            margin-top: 30px;
            justify-content: space-between;
        }
    }
`

const Award = styled.div`
    position: relative;
    width: 33.3333%;
    display: flex;
    justify-content: center;
    padding: 0 40px;


    .gatsby-image-wrapper {
        width: 260px;
        height: auto;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
        width: 50%;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        width: 100%;
    }
`

export default {
    Awards,
    Award
}