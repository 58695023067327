import React from "react";
import { graphql } from "gatsby";
import Container from "../../layout/Container";
import S from "./styles";
import { ContentfulUnderlinedHeading } from '../../../types';
import AnimatedHeading from "../../common/AnimatedHeading";

export const fragment = graphql`
  fragment UnderlinedHeading on ContentfulUnderlinedHeading {
    id
    internal {
      type
    }
    colourScheme
    underlinedHeading: heading {
      heading
    }
  }
`;

export interface UnderlinedHeadingProps extends ContentfulUnderlinedHeading {
  [prop: string]: any;
}

const UnderlinedHeading = ({ underlinedHeading, colourScheme, ...rest }: UnderlinedHeadingProps) => {
  return (
    <Container>
      <S.UnderlinedHeading colourScheme={colourScheme} {...rest}>
        <AnimatedHeading colourScheme={colourScheme}>{underlinedHeading.heading}</AnimatedHeading>
      </S.UnderlinedHeading>
    </Container>
  );
};

export default UnderlinedHeading;
